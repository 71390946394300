var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { format } from 'date-fns';
import { enUS, bg } from 'date-fns/locale';
import React, { useCallback } from 'react';
import eng from './shared/en.json';
import bgl from './shared/bg.json';
import { configLocale } from 'app/util/siteConfig';
import { useHistory } from 'react-router-dom';
var validLocales = ['bg', 'en'];
var translations = {
    bg: bgl,
    en: eng,
};
export var LOCALE_LOCAL_STORAGE_KEY = configLocale;
var defaultLocale = 'bg';
export var LocaleContext = React.createContext({
    locale: 'bg',
    updateLocale: function (newLocale, old) { },
});
export var LocaleProvider = function (_a) {
    var children = _a.children;
    var history = useHistory();
    var pathLocale = history.location.pathname.split('/')[1];
    var _b = React.useState(validLocales.includes(pathLocale) ? pathLocale : defaultLocale), locale = _b[0], setLocale = _b[1];
    React.useEffect(function () {
        var savedLocale = localStorage.getItem(LOCALE_LOCAL_STORAGE_KEY) || defaultLocale;
        setLocale(savedLocale);
    }, []);
    var updateLocale = React.useCallback(function (newLocale, old) {
        localStorage.setItem(LOCALE_LOCAL_STORAGE_KEY, newLocale);
        var newPathname = history.location.pathname.includes("/" + old + "/")
            ? history.location.pathname.replace("/" + old + "/", "/" + newLocale + "/")
            : "/" + newLocale + history.location.pathname;
        history.push(newPathname + history.location.search);
        setLocale(newLocale);
    }, []);
    var value = React.useMemo(function () {
        return { locale: locale, updateLocale: updateLocale };
    }, [locale, updateLocale]);
    return (React.createElement(LocaleContext.Provider, { value: value }, children));
};
function translate(locale, translationKey, vars) {
    var translation = translations[locale][translationKey];
    if (!translation) {
        //return `translation for ${translationKey} not found`;
        return translationKey;
    }
    if (vars) {
        return Object.entries(vars).reduce(function (acc, _a) {
            var k = _a[0], v = _a[1];
            return acc.split("{{" + k + "}}").join(v);
        }, translation);
    }
    else {
        return translation;
    }
}
export function useLocaleId() {
    var locale = React.useContext(LocaleContext).locale;
    return locale;
}
export var localeMap = {
    bg: bg,
    en: enUS,
};
export function useDateFormat() {
    var localeId = useLocaleId();
    var decoratedFormat = function (date, _format, options) {
        var newOptions = __assign({}, options);
        if (!(options === null || options === void 0 ? void 0 : options.locale)) {
            newOptions.locale = localeMap[localeId];
        }
        return format(date, _format, newOptions);
    };
    return decoratedFormat;
}
export var currencySymbolMapping = {
    EUR: '€',
};
export default function useTranslation() {
    var locale = useLocaleId();
    var translateFn = useCallback(function (translationKey, vars) {
        return translate(locale, translationKey, vars);
    }, [locale]);
    return translateFn;
}
