var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Alert, Button, CircularProgress, Divider, Grid, MenuItem, Snackbar, Stack, TextField, Typography, Box, useMediaQuery, } from '@material-ui/core';
import styled from '@emotion/styled';
import MuiPhoneNumber from 'material-ui-phone-number';
import React, { useContext, useEffect, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { Gender, useChangeUserPasswordMutation, useUpdateProfileMutation, } from '../../generated/graphql';
import { AuthContext } from '../../hooks/useAuth';
import useTranslation, { LocaleContext, } from '../../hooks/useTranslation';
import LatinLettersField from '../fields/LatinLettersField';
import { MaskedDateField } from '../fields/MaskedDayField';
import chevron from '../../assets/illustrations/chevron-up-black.svg';
import ArrowForward from '../../assets/icons/ArrowForward';
import { configSiteName } from 'app/util/siteConfig';
var ProfileForm = function (_a) {
    var _b, _c;
    var profile = _a.profile, email = _a.email;
    var localeContext = React.useContext(LocaleContext);
    var languages = {
        bg: 'BG',
        en: 'EN',
    };
    var defaultValues = {
        firstName: (profile === null || profile === void 0 ? void 0 : profile.firstName) || '',
        lastName: (profile === null || profile === void 0 ? void 0 : profile.lastName) || '',
        phone: (profile === null || profile === void 0 ? void 0 : profile.phone) || '',
        language: (profile === null || profile === void 0 ? void 0 : profile.language) || localeContext.locale,
        dateOfBirth: ((_b = profile === null || profile === void 0 ? void 0 : profile.dateOfBirth) === null || _b === void 0 ? void 0 : _b.split('-').reverse().join('.')) || undefined,
        email: email,
        gender: ((_c = profile === null || profile === void 0 ? void 0 : profile.gender) === null || _c === void 0 ? void 0 : _c.toLowerCase()) || undefined,
    };
    var context = useContext(AuthContext);
    var isMobile = useMediaQuery(function (theme) { return theme.breakpoints.down('sm'); });
    var _d = useState(false), isSnackOpen = _d[0], setIsSnackOpen = _d[1];
    var _e = useState(false), isPWSnackOpen = _e[0], setIsPWSnackOpen = _e[1];
    var _f = useState('ro'), countryCode = _f[0], setCountryCode = _f[1];
    useEffect(function () {
        fetch('https://ipapi.co/json/')
            .then(function (res) { return res.json(); })
            .then(function (response) {
            setCountryCode(response.country_code);
        })
            .catch(function (data) {
            console.error('Request failed: ', data);
        });
    }, []);
    var methods = useForm({ defaultValues: defaultValues });
    // methods.watch();
    var t = useTranslation();
    var _g = useUpdateProfileMutation({
        onCompleted: function (_a) {
            var _b;
            var updateProfile = _a.updateProfile;
            if ((updateProfile === null || updateProfile === void 0 ? void 0 : updateProfile.__typename) === 'ValidationErrors') {
                (_b = updateProfile.fields) === null || _b === void 0 ? void 0 : _b.forEach(function (f) {
                    //@ts-ignore
                    methods.setError(f.path, {
                        type: 'manual',
                        message: f === null || f === void 0 ? void 0 : f.messages[0],
                    });
                });
            }
            if ((updateProfile === null || updateProfile === void 0 ? void 0 : updateProfile.__typename) === 'Profile') {
                methods.clearErrors();
                if (context.authData.user) {
                    var newUser = __assign(__assign({}, context.authData.user), { profile: __assign({}, updateProfile) });
                    context.setUser(newUser);
                }
                setIsSnackOpen(true);
            }
        },
    }), updateProfile = _g[0], updateData = _g[1];
    var genderMap = {
        mr: Gender.Mr,
        ms: Gender.Ms,
    };
    var onSubmit = function (e) {
        var _a;
        e.preventDefault();
        var profileValues = methods.getValues();
        updateProfile({
            variables: {
                input: {
                    firstName: profileValues.firstName,
                    lastName: profileValues.lastName,
                    phone: profileValues.phone,
                    language: profileValues.language,
                    dateOfBirth: (_a = profileValues.dateOfBirth) === null || _a === void 0 ? void 0 : _a.split('.').reverse().join('-'),
                    gender: profileValues.gender
                        ? genderMap[profileValues.gender]
                        : undefined,
                },
            },
        });
    };
    var handleClose = function (event, reason) {
        if (reason === 'clickaway') {
            return;
        }
        setIsSnackOpen(false);
        setIsPWSnackOpen(false);
    };
    var defaultPasswordChangeValues = {
        currentPassword: '',
        newPassword: '',
    };
    var passwordMethods = useForm({
        defaultValues: defaultPasswordChangeValues,
    });
    passwordMethods.watch();
    var pwValues = passwordMethods.getValues();
    var _h = useChangeUserPasswordMutation({
        variables: {
            currentPassword: pwValues.currentPassword,
            newPassword: pwValues.newPassword,
        },
        onCompleted: function (_a) {
            var _b;
            var changeUserPassword = _a.changeUserPassword;
            if ((changeUserPassword === null || changeUserPassword === void 0 ? void 0 : changeUserPassword.__typename) === 'ValidationErrors') {
                (_b = changeUserPassword.fields) === null || _b === void 0 ? void 0 : _b.map(function (f) {
                    //@ts-ignore
                    passwordMethods.setError(f === null || f === void 0 ? void 0 : f.path, {
                        type: 'manual',
                        message: f === null || f === void 0 ? void 0 : f.messages[0],
                    });
                });
            }
            if ((changeUserPassword === null || changeUserPassword === void 0 ? void 0 : changeUserPassword.__typename) === 'User') {
                setIsPWSnackOpen(true);
                context.setUser(changeUserPassword);
                passwordMethods.reset();
            }
        },
    }), changePassword = _h[0], changePasswordData = _h[1];
    var customPasswordChangeSubmit = function (e) {
        e.preventDefault();
        passwordMethods.clearErrors();
        changePassword();
    };
    var genderOptions = [
        { text: '', identifier: '' },
        { text: t('sex.mr'), identifier: 'mr' },
        { text: t('sex.ms'), identifier: 'ms' },
    ];
    var snackbar = (React.createElement(Snackbar, { open: isSnackOpen, anchorOrigin: { vertical: 'bottom', horizontal: 'center' }, autoHideDuration: 4000, onClose: handleClose },
        React.createElement(Alert, { severity: "success", onClose: handleClose }, t('profile.modified'))));
    var passwordSnackbar = (React.createElement(Snackbar, { open: isPWSnackOpen, anchorOrigin: { vertical: 'bottom', horizontal: 'center' }, autoHideDuration: 4000, onClose: handleClose },
        React.createElement(Alert, { severity: "success", onClose: handleClose }, t('profile.pass.modified'))));
    var StyledMuiPhoneNumber = styled(MuiPhoneNumber)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    .MuiPhoneNumber-flagButton {\n      width: 36px;\n      height: 16px;\n      margin: 12px 0 0 -4px;\n      svg {\n        width: 50%;\n      }\n      &:before {\n        content: '';\n        width: 50%;\n        height: 100%;\n        background: url(", ");\n        background-size: 100%;\n        background-position: left center;\n      }\n    }\n  "], ["\n    .MuiPhoneNumber-flagButton {\n      width: 36px;\n      height: 16px;\n      margin: 12px 0 0 -4px;\n      svg {\n        width: 50%;\n      }\n      &:before {\n        content: '';\n        width: 50%;\n        height: 100%;\n        background: url(", ");\n        background-size: 100%;\n        background-position: left center;\n      }\n    }\n  "])), chevron);
    return (React.createElement(Stack, null,
        snackbar,
        React.createElement(Typography, { variant: "h3", sx: { mt: 2 } }, t('profile.welcome', { configSiteName: configSiteName })),
        React.createElement(Typography, { variant: "body2", sx: { mt: 1, mb: 2 } }, t('profile.welcome.desc', { configSiteName: configSiteName })),
        React.createElement(Typography, { variant: "h3", sx: { mt: 2 } }, t('profile.settings')),
        React.createElement(Divider, { variant: "fullWidth", sx: { my: 1 } }),
        React.createElement(FormProvider, __assign({}, methods),
            React.createElement("form", { onSubmit: onSubmit },
                React.createElement(Stack, null,
                    React.createElement(Grid, { container: true, sx: { mt: 2 }, spacing: 2 },
                        React.createElement(Grid, { md: 2, xs: 3, item: true },
                            React.createElement(Controller, { name: "gender", control: methods.control, rules: {
                                    required: t('formErrors.requiredField'),
                                }, render: function (_a) {
                                    var _b, _c, _d;
                                    var field = _a.field, fieldState = _a.fieldState, formState = _a.formState;
                                    return (React.createElement(TextField, __assign({}, field, { disabled: formState.isSubmitting, error: !!((_b = fieldState.error) === null || _b === void 0 ? void 0 : _b.message), helperText: (_c = fieldState.error) === null || _c === void 0 ? void 0 : _c.message, sx: { width: '100%' }, id: "gender", variant: "outlined", label: t("passengerForm.sex"), value: ((_d = genderOptions.find(function (option) { return option.identifier === field.value; })) === null || _d === void 0 ? void 0 : _d.identifier) || '', select: true }), genderOptions.map(function (option, i) { return (React.createElement(MenuItem, { disabled: i === 0, value: option.identifier }, option.text)); })));
                                } })),
                        React.createElement(Grid, { md: 3, xs: 9, item: true },
                            React.createElement(Controller, { name: "firstName", rules: {
                                    required: '',
                                }, control: methods.control, render: function (_a) {
                                    var _b, _c;
                                    var field = _a.field, fieldState = _a.fieldState, formState = _a.formState;
                                    return (React.createElement(LatinLettersField, __assign({ label: t('passengerForm.name') }, field, { fullWidth: true, disabled: formState.isSubmitting, error: !!((_b = fieldState.error) === null || _b === void 0 ? void 0 : _b.message), helperText: (_c = fieldState.error) === null || _c === void 0 ? void 0 : _c.message, inputProps: {
                                            autoComplete: 'given-name',
                                        } })));
                                } })),
                        React.createElement(Grid, { md: 3, xs: 12, item: true },
                            React.createElement(Controller, { name: "lastName", rules: {
                                    required: '',
                                }, control: methods.control, render: function (_a) {
                                    var _b, _c;
                                    var field = _a.field, fieldState = _a.fieldState, formState = _a.formState;
                                    return (React.createElement(LatinLettersField, __assign({ label: t('passengerForm.surname') }, field, { fullWidth: true, disabled: formState.isSubmitting, error: !!((_b = fieldState.error) === null || _b === void 0 ? void 0 : _b.message), helperText: (_c = fieldState.error) === null || _c === void 0 ? void 0 : _c.message, inputProps: {
                                            autoComplete: 'family-name',
                                        } })));
                                } })),
                        React.createElement(Grid, { md: 3, xs: 12, item: true },
                            React.createElement(Controller, { name: "email", control: methods.control, rules: {
                                    required: '',
                                }, render: function (_a) {
                                    var _b, _c;
                                    var field = _a.field, fieldState = _a.fieldState, formState = _a.formState;
                                    return (React.createElement(TextField, __assign({}, field, { disabled: true, error: !!((_b = fieldState.error) === null || _b === void 0 ? void 0 : _b.message), helperText: (_c = fieldState.error) === null || _c === void 0 ? void 0 : _c.message, sx: { width: '100%' }, id: "email", variant: "outlined", label: "email" })));
                                } }))),
                    React.createElement(Grid, { container: true, spacing: 2, sx: { mt: 0 } },
                        React.createElement(Grid, { md: 3, xs: 12, item: true },
                            React.createElement(Controller, { name: "phone", control: methods.control, render: function (_a) {
                                    var _b, _c;
                                    var field = _a.field, fieldState = _a.fieldState, formState = _a.formState;
                                    return (React.createElement(StyledMuiPhoneNumber, __assign({}, field, { value: field.value, disabled: formState.isSubmitting, error: !!((_b = fieldState.error) === null || _b === void 0 ? void 0 : _b.message), helperText: (_c = fieldState.error) === null || _c === void 0 ? void 0 : _c.message, InputLabelProps: { shrink: false }, sx: { width: '100%' }, variant: "outlined", label: t('form.phone'), type: "tel", defaultCountry: countryCode.toLowerCase(), preferredCountries: [
                                            'ro',
                                            'it',
                                            'fr',
                                            'md',
                                            'gb',
                                            'ie',
                                        ], autoFormat: false })));
                                } })),
                        React.createElement(Grid, { md: 3, xs: 12, item: true },
                            React.createElement(Controller, { rules: {
                                    required: t('formErrors.requiredField'),
                                    minLength: {
                                        value: 10,
                                        message: t('formErrors.fullDate'),
                                    },
                                }, name: "dateOfBirth", control: methods.control, render: function (_a) {
                                    var _b, _c;
                                    var field = _a.field, fieldState = _a.fieldState, formState = _a.formState;
                                    return (React.createElement(MaskedDateField, __assign({ label: t('passengerForm.dateOfBirth') }, field, { reverseYears: true, minYear: 1900, maxYear: 2023, disabled: formState.isSubmitting, error: !!((_b = fieldState.error) === null || _b === void 0 ? void 0 : _b.message), helperText: (_c = fieldState.error) === null || _c === void 0 ? void 0 : _c.message })));
                                } }))),
                    React.createElement(Grid, { item: true, sx: { my: 2 } },
                        React.createElement(Button, { type: "submit", variant: "contained", disabled: updateData.loading, sx: {
                                width: { lg: 250, xs: '100%' },
                                height: '48px',
                                alignSelf: 'flex-start',
                            } }, !!updateData.loading ? (React.createElement(CircularProgress, null)) : (React.createElement(Box, { sx: {
                                display: 'flex',
                                alignItems: 'center',
                            } },
                            React.createElement(ArrowForward, { viewBox: "-9 -7 24 24" }),
                            React.createElement(Box, { sx: { maxWidth: 'calc(100% - 24px)' } }, t('common.save'))))))))),
        passwordSnackbar,
        React.createElement(Typography, { variant: "h3", sx: { mt: 2 } }, t('profile.accessSettings')),
        React.createElement(Divider, { variant: "fullWidth", sx: { my: 1 } }),
        React.createElement(FormProvider, __assign({}, passwordMethods),
            React.createElement("form", { onSubmit: customPasswordChangeSubmit },
                React.createElement(Stack, { sx: { mt: 2 } },
                    React.createElement(Grid, { container: true, item: true, md: 3, xs: 12, spacing: 2, direction: "column" },
                        React.createElement(Grid, { item: true, md: 3, xs: 12 },
                            React.createElement(Controller, { name: "currentPassword", control: passwordMethods.control, rules: {
                                    required: '',
                                }, render: function (_a) {
                                    var _b, _c;
                                    var field = _a.field, fieldState = _a.fieldState, formState = _a.formState;
                                    return (React.createElement(TextField, __assign({}, field, { type: "password", error: !!((_b = fieldState.error) === null || _b === void 0 ? void 0 : _b.message), helperText: (_c = fieldState.error) === null || _c === void 0 ? void 0 : _c.message, sx: { width: '100%' }, id: "currentPassword", variant: "outlined", label: t('common.current_password') })));
                                } })),
                        React.createElement(Grid, { item: true, md: 3, xs: 12 },
                            React.createElement(Controller, { name: "newPassword", control: passwordMethods.control, rules: {
                                    required: '',
                                }, render: function (_a) {
                                    var _b, _c;
                                    var field = _a.field, fieldState = _a.fieldState, formState = _a.formState;
                                    return (React.createElement(TextField, __assign({}, field, { type: "password", error: !!((_b = fieldState.error) === null || _b === void 0 ? void 0 : _b.message), helperText: (_c = fieldState.error) === null || _c === void 0 ? void 0 : _c.message, sx: { width: '100%' }, id: "newPassword", variant: "outlined", label: t('common.new_password') })));
                                } })),
                        React.createElement(Grid, { item: true },
                            React.createElement(Button, { type: "submit", variant: "contained", disabled: changePasswordData.loading ||
                                    !pwValues.currentPassword ||
                                    !pwValues.newPassword, sx: {
                                    width: { lg: 250, xs: '100%' },
                                    height: '48px',
                                    alignSelf: 'flex-start',
                                    '&.Mui-disabled': {
                                        '& path': {
                                            stroke: 'rgba(0, 0, 0, 0.26)',
                                        },
                                    },
                                } }, changePasswordData.loading ? (React.createElement(CircularProgress, null)) : (React.createElement(Box, { sx: {
                                    display: 'flex',
                                    alignItems: 'center',
                                } },
                                React.createElement(ArrowForward, { viewBox: "-9 -7 24 24" }),
                                React.createElement(Box, { sx: { maxWidth: 'calc(100% - 24px)' } }, t('common.change'))))))))))));
};
export default ProfileForm;
var templateObject_1;
