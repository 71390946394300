var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Button, Card, Stack, Tooltip, Typography, useMediaQuery, } from '@material-ui/core';
import { Train } from '@material-ui/icons';
import { Box } from '@material-ui/system';
import React, { useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { default as AirportChange, default as SmartConnections, } from '../../assets/icons/AirportChange';
import uniq from 'lodash.uniq';
import ArrowForward from '../../assets/icons/ArrowForward';
import CheckinRe from '../../assets/icons/CheckinRe';
import ExpandMore from '../../assets/icons/ExpandMore';
import Moon from '../../assets/icons/Moon';
import NoLuggage from '../../assets/icons/NoLuggage';
import CheckinRequiredIcon from '../../assets/icons/CheckinRequiredIcon';
import { DepartureDateType, ReturnDateType, } from '../../generated/graphql';
import { BaggageDetails } from './BaggageDetails';
import { FareRules } from './FareRules';
import Flight from './Flight';
import FlightCardMobile from './FlightCardMobile';
import useTranslation, { useLocaleId } from '../../hooks/useTranslation';
import getCurrencySymbol from 'app/util/getCurrencySymbol';
import CheckInRequiredPayments from '../../assets/icons/CheckinRequredPayments';
var FlightCard = function (_a) {
    var _b, _c, _d, _e, _f, _g, _h, _j, _k, _l;
    var itinerary = _a.itinerary, noPrice = _a.noPrice, departureDateType = _a.departureDateType, noBaggageDetails = _a.noBaggageDetails, _m = _a.open, open = _m === void 0 ? false : _m, itinerarySearch = _a.itinerarySearch, passengers = _a.passengers, isCheckinRequired = _a.isCheckinRequired;
    var _o = useState(open), isOpen = _o[0], setIsOpen = _o[1];
    var isMobile = useMediaQuery(function (theme) { return theme.breakpoints.down('sm'); }, {
        noSsr: true,
    });
    var totalPassengerNumber = passengers && Object.values(passengers).reduce(function (a, b) { return a + b; });
    var templateBreakdown = [];
    if (itinerarySearch && Array.isArray(itinerarySearch)) {
        templateBreakdown = itinerarySearch.map(function (fl) {
            var _a;
            return {
                type: fl.source.length > 1 || fl.destination.length > 1
                    ? 'city'
                    : ((_a = fl.departureDate) === null || _a === void 0 ? void 0 : _a.type) === DepartureDateType.Range
                        ? 'range'
                        : 'simple',
            };
        });
    }
    else if (itinerarySearch && 'returnDate' in itinerarySearch) {
        templateBreakdown = [
            {
                type: itinerarySearch.source.length > 1 ||
                    itinerarySearch.destination.length > 1
                    ? 'city'
                    : ((_b = itinerarySearch.departureDate) === null || _b === void 0 ? void 0 : _b.type) === DepartureDateType.Range
                        ? 'range'
                        : 'simple',
            },
            {
                type: itinerarySearch.source.length > 1 ||
                    itinerarySearch.destination.length > 1
                    ? 'city'
                    : ((_c = itinerarySearch.returnDate) === null || _c === void 0 ? void 0 : _c.type) === ReturnDateType.Range ||
                        ((_d = itinerarySearch.returnDate) === null || _d === void 0 ? void 0 : _d.type) === ReturnDateType.Duration
                        ? 'range'
                        : 'simple',
            },
        ];
    }
    else {
        templateBreakdown = [
            {
                type: (itinerarySearch && itinerarySearch.source.length > 1) ||
                    (itinerarySearch && itinerarySearch.destination.length > 1)
                    ? 'city'
                    : itinerarySearch &&
                        ((_e = itinerarySearch.departureDate) === null || _e === void 0 ? void 0 : _e.type) === DepartureDateType.Range
                        ? 'range'
                        : 'simple',
            },
        ];
    }
    var t = useTranslation();
    var sectors = useMemo(function () {
        var sectorsCopy = itinerary.sector.map(function (sector, index) {
            var _a;
            return (__assign(__assign({}, sector), { templateType: itinerarySearch
                    ? ((_a = templateBreakdown === null || templateBreakdown === void 0 ? void 0 : templateBreakdown[index]) === null || _a === void 0 ? void 0 : _a.type) || 'simple'
                    : 'simple' }));
        });
        itinerary.sector.forEach(function (sector, index) {
            var nextSector = itinerary.sector[index + 1];
            if (nextSector) {
                var lastCode = sector.segments[sector.segments.length - 1].segment.destination
                    .station.code;
                if (lastCode) {
                    var nextCode = nextSector.segments[0].segment.source.station.code;
                    if (nextCode && nextCode !== lastCode) {
                        sectorsCopy[index].lastCodeWarning = t('common.arrive_at') + " " + sector.segments[sector.segments.length - 1].segment.destination
                            .station.name + " " + sector.segments[sector.segments.length - 1].segment.destination
                            .station.code + ",\n            " + t('common.return_from') + " " + nextSector.segments[0].segment.source.station.name + " " + nextSector.segments[0].segment.source.station.code;
                        sectorsCopy[index + 1].firstCodeWarning = t('common.arrive_at') + " " + sector.segments[sector.segments.length - 1].segment.destination
                            .station.name + " " + sector.segments[sector.segments.length - 1].segment.destination
                            .station.code + ",\n            " + t('common.return_from') + " " + nextSector.segments[0].segment.source.station.name + " " + nextSector.segments[0].segment.source.station.code;
                    }
                }
            }
        });
        return sectorsCopy;
    }, [itinerary.sector]);
    var locale = useLocaleId();
    var badgesMap = {
        night: (React.createElement(Tooltip, { title: t('night_stopover'), placement: "top", leaveTouchDelay: 3000, enterTouchDelay: 0, enterDelay: 0, onClick: function (e) { return e.stopPropagation(); } },
            React.createElement(Box, { pr: 0.5 },
                React.createElement(Moon, { viewBox: "0 -6 24 24" })))),
        no_luggage: (React.createElement(Tooltip, { title: t('without_checked_baggage'), placement: "top", leaveTouchDelay: 3000, enterTouchDelay: 0, onClick: function (e) { return e.stopPropagation(); } },
            React.createElement(Box, { pr: 0.5 },
                React.createElement(NoLuggage, { viewBox: "0 -8 24 24" })))),
        change_airport: (React.createElement(Tooltip, { title: t('airport_change'), placement: "top", leaveTouchDelay: 3000, enterTouchDelay: 0, onClick: function (e) { return e.stopPropagation(); } },
            React.createElement(Box, { pr: 0.5 },
                React.createElement(AirportChange, null)))),
        re_baggage: (React.createElement(Tooltip, { title: t('self_transfer'), placement: "top", leaveTouchDelay: 3000, enterTouchDelay: 0, onClick: function (e) { return e.stopPropagation(); } },
            React.createElement(Box, { pr: 0.5 },
                React.createElement(CheckinRe, null)))),
        smart_connection: (React.createElement(Tooltip, { title: t('footer.connectingFlight'), placement: "top", leaveTouchDelay: 3000, enterTouchDelay: 0, onClick: function (e) { return e.stopPropagation(); } },
            React.createElement(Box, { pr: 0.5 },
                React.createElement(SmartConnections, { viewBox: "0 -3 28 28" })))),
        train: (React.createElement(Tooltip, { title: t('ground_transport_segment'), placement: "top", leaveTouchDelay: 3000, enterTouchDelay: 0, onClick: function (e) { return e.stopPropagation(); } },
            React.createElement(Box, { pr: 0.5 },
                React.createElement(Train, null)))),
    };
    useEffect(function () {
        open ? setIsOpen(true) : setIsOpen(false);
    }, []);
    return isMobile ? (React.createElement(FlightCardMobile, { noBaggageDetails: noBaggageDetails, totalPassengerNumber: totalPassengerNumber, itinerary: itinerary, sectors: sectors, noPrice: noPrice, isCheckinRequired: isCheckinRequired })) : (React.createElement(Card, { sx: {
            width: '100%',
            mt: 2,
            alignSelf: 'flex-end',
        }, onClick: function () { return setIsOpen(!isOpen); } },
        React.createElement(Stack, { direction: "row", justifyContent: "space-between" },
            React.createElement(Stack, { sx: { flex: 1 } },
                React.createElement(Stack, { direction: "row", p: 2, sx: { borderBottom: '1px solid #E3F2FD' }, spacing: 3, justifyContent: "space-between" },
                    React.createElement(Stack, { direction: "row", spacing: 3 },
                        !noBaggageDetails && React.createElement(BaggageDetails, { offerId: itinerary.id }),
                        React.createElement(FareRules, { offerId: itinerary.id }),
                        itinerary.info && (React.createElement(Stack, { sx: { color: 'error.main' } },
                            itinerary.info.provider,
                            " ",
                            itinerary.info.operatingCarrier,
                            ' ', (_f = itinerary.info.serviceFee) === null || _f === void 0 ? void 0 :
                            _f.amount,
                            ' ', (_h = (_g = itinerary.info.serviceFee) === null || _g === void 0 ? void 0 : _g.currency) === null || _h === void 0 ? void 0 :
                            _h.code,
                            itinerary.info.virtualInterlining ? 'VI' : ''))),
                    React.createElement(Stack, { direction: "row" }, uniq(itinerary.sector
                        .map(function (s) { return s.badges; })
                        .flat()
                        .map(function (b) { return b && badgesMap[b]; })))),
                React.createElement(Stack, { direction: "row", justifyContent: "space-between" },
                    React.createElement(Stack, { sx: { flex: 1 } },
                        React.createElement(Stack, { direction: sectors.length !== 1 && isOpen ? 'row' : 'column', sx: {
                                flexWrap: sectors.length !== 1 && isOpen ? 'wrap' : 'unset',
                            } }, sectors.map(function (sector, index) { return (React.createElement(Flight, { key: index, sector: sector, expanded: isOpen, flightIndex: index, oneWay: sectors.length === 1, last: index === sectors.length - 1 })); })),
                        isOpen && isCheckinRequired && (React.createElement(Stack, { sx: { px: 2, py: 1 } },
                            React.createElement(Stack, { direction: "row", alignItems: "center", sx: { fontWeight: 600 } },
                                React.createElement(CheckinRequiredIcon, null),
                                t('flightCard.checkInAutomat'),
                                React.createElement(Stack, { alignItems: "center", direction: "row", sx: {
                                        ml: 2,
                                        borderRadius: 4,
                                        px: 2,
                                        background: '#4BC25F',
                                        color: 'common.white',
                                    } },
                                    React.createElement(CheckInRequiredPayments, null),
                                    React.createElement(Typography, { sx: { ml: 1 } }, t('flightCard.checkInAutomatFree')))),
                            React.createElement(Stack, { sx: { py: 2, mt: 1, display: 'inline' } },
                                React.createElement(Typography, { sx: { display: 'inline' } }, t('flightCard.checkInAutomatText')),
                                ' ',
                                React.createElement(Link, { to: "/" + locale + "/check-in", target: "_blank" }, t('flightCard.checkInAutomatButtonText')))))))),
            noPrice ? null : (React.createElement(Stack, { sx: {
                    position: 'relative',
                    borderLeft: '1px solid #E3F2FD',
                    p: 2,
                    pb: 1,
                    width: 194,
                    justifyContent: isOpen ? 'start' : 'center',
                } },
                React.createElement(Stack, { sx: isOpen ? { mt: '34px' } : {} },
                    React.createElement(Typography, { alignSelf: "center", variant: "h2" },
                        ((_k = (_j = itinerary === null || itinerary === void 0 ? void 0 : itinerary.price) === null || _j === void 0 ? void 0 : _j.currency) === null || _k === void 0 ? void 0 : _k.code) &&
                            getCurrencySymbol(itinerary.price.currency.code),
                        ' ',
                        (parseFloat(itinerary.price.amount) / totalPassengerNumber).toFixed(2)),
                    React.createElement(Typography, { sx: { alignSelf: 'center', mb: 2, textAlign: 'center' }, color: "text.secondary", variant: "subtitle1" },
                        t('flightCard.tarifPerPassenger'),
                        itinerary.fee &&
                            " + " + getCurrencySymbol((_l = itinerary.fee.currency) === null || _l === void 0 ? void 0 : _l.code) + (parseFloat(itinerary.fee.amount) / totalPassengerNumber).toFixed() + " \n                   " + t('flightCard.tsf')),
                    React.createElement(Button, { component: Link, variant: "contained", to: "/" + locale + "/booking/" + itinerary.id },
                        React.createElement(ArrowForward, { viewBox: "-9 -7 24 24" }),
                        React.createElement(Typography, { variant: "body2", sx: { width: '99px', textAlign: 'center' } }, t('flightCard.select')))),
                React.createElement(Box, { sx: {
                        mt: 3,
                        '& button': { mx: 1 },
                        display: 'flex',
                        justifyContent: 'flex-end',
                    } },
                    React.createElement(Stack, { direction: "row", my: 1, sx: { position: 'absolute', mb: 0, bottom: 0 }, justifyContent: "center" },
                        React.createElement(Button, { sx: {
                                fontSize: '12px',
                                px: 0,
                                marginRight: '-4px !important',
                            } },
                            t('flightCard.details'),
                            React.createElement(ExpandMore, { viewBox: "-7 -8 21 21", sx: { stroke: '#1976D2' } })))))))));
};
export default FlightCard;
