var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Alert, AlertTitle, Button, Card, Checkbox, CircularProgress, Dialog, Divider, FormControl, FormControlLabel, Stack, Typography, useMediaQuery, } from '@material-ui/core';
import { FormatListBulleted } from '@material-ui/icons';
import { Box } from '@material-ui/system';
import { get } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useAcceptBookingPriceChangeMutation, useCheckBookingStatusLazyQuery, useCreateBookingPaymentMutation, } from 'app/generated/graphql';
import HowPay from './HowPay';
import PaymentSummary from './PaymentSummary';
import { BillingData } from 'app/components/Order/BillingData';
import ErrorHelperText from 'app/components/Mui/Error/ErrorHelperText';
import PaymentMethod from 'app/components/Order/Payment/PaymentMethod';
import useTranslation, { useLocaleId } from 'app/hooks/useTranslation';
import BankCardIcon from 'app/assets/icons/bank-card.svg';
import MoneyTransferIcon from 'app/assets/icons/money-transfer.svg';
import { useTheme } from '@mui/material';
import Voucher from '../Voucher';
import getCurrencySymbol from 'app/util/getCurrencySymbol';
import { useHistory } from 'react-router-dom';
import { configBillingFormDataCustomer } from 'app/util/siteConfig';
import ArrowForward from '../../../assets/icons/ArrowForward';
var iconsMap = {
    card_redirect: BankCardIcon,
    bank_transfer: MoneyTransferIcon,
};
var Payment = function (_a) {
    var _b, _c, _d, _e, _f, _g, _h, _j;
    var payments = _a.payments, bookingId = _a.bookingId, price = _a.price, promoFareWarning = _a.promoFareWarning, showVoucher = _a.showVoucher, refetch = _a.refetch;
    var isMobile = useMediaQuery(function (theme) { return theme.breakpoints.down('sm'); });
    var theme = useTheme();
    var payButtonRef = React.createRef();
    function download(url) {
        var a = document.createElement('a');
        a.href = url;
        a.download = url.split('/').pop();
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    }
    var defaultValues = {
        bookingId: bookingId,
        methodHandle: 'card_redirect',
        gatewayId: null,
        terms: false,
        currency: payments.baseCurrency.code,
    };
    var methods = useForm({
        defaultValues: defaultValues,
        shouldUnregister: true,
    });
    var watchGatewayName = methods.watch('methodHandle', '');
    //@ts-ignore
    var watchCurrency = methods.watch('currency', payments.baseCurrency.code);
    var selectedMethods = payments.methods.filter(function (mtd) { return mtd.methodHandle === watchGatewayName; });
    var _k = useAcceptBookingPriceChangeMutation({
        variables: { bookingUuid: bookingId },
        onCompleted: function (_a) {
            var acceptBookingPriceChange = _a.acceptBookingPriceChange;
            if ((acceptBookingPriceChange === null || acceptBookingPriceChange === void 0 ? void 0 : acceptBookingPriceChange.__typename) ===
                'AcceptBookingPriceChangeResult') {
                setShowChangePriceAlert(false);
                refetch && refetch();
            }
        },
    }), acceptPriceChange = _k[0], acceptPriceChangeResult = _k[1];
    var handleAcceptPriceChangeClick = function () {
        acceptPriceChange();
    };
    var history = useHistory();
    var handleDeclinePriceChange = function () {
        history.replace('/');
    };
    var aggregatedCurrencies = [payments.baseCurrency];
    var scrollToFirstErrors = function () {
        var _a;
        var state = methods.formState;
        if (!state.isSubmitting && !state.isValid && !state.isValidating) {
            var fieldsOrder_1 = ['customer', 'methodHandle'];
            var elements = Object.keys(state.errors)
                .sort(function (a, b) {
                if (fieldsOrder_1.indexOf(a) === -1)
                    return 1;
                if (fieldsOrder_1.indexOf(b) === -1)
                    return -1;
                return fieldsOrder_1.indexOf(a) - fieldsOrder_1.indexOf(b);
            })
                .map(function (name) { return document.getElementsByName(name)[0]; })
                .filter(function (el) { return !!el; });
            (_a = elements[0]) === null || _a === void 0 ? void 0 : _a.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    };
    var _l = useCreateBookingPaymentMutation({
        onCompleted: function (_a) {
            var _b, _c;
            var createBookingPayment = _a.createBookingPayment;
            if ((createBookingPayment === null || createBookingPayment === void 0 ? void 0 : createBookingPayment.__typename) === 'BookingInvoice') {
                var downloadUrl = get(createBookingPayment, 'downloadUrl');
                download(downloadUrl);
            }
            if ((createBookingPayment === null || createBookingPayment === void 0 ? void 0 : createBookingPayment.__typename) === 'BookingPaymentRedirect') {
                var form_1 = document.createElement('form');
                form_1.style.cssText =
                    'position:absolute;top:0;left:0;width:1px;height:1px;display:none;';
                form_1.method = createBookingPayment.method;
                (_b = createBookingPayment.inputs) === null || _b === void 0 ? void 0 : _b.forEach(function (inp) {
                    var element = document.createElement('input');
                    //@ts-ignore
                    element.name = inp.name;
                    //@ts-ignore
                    element.value = inp.value;
                    form_1.appendChild(element);
                });
                form_1.action = createBookingPayment.url;
                document.body.appendChild(form_1);
                form_1.submit();
            }
            if ((createBookingPayment === null || createBookingPayment === void 0 ? void 0 : createBookingPayment.__typename) === 'ValidationErrors') {
                (_c = createBookingPayment.fields) === null || _c === void 0 ? void 0 : _c.forEach(function (error) {
                    methods.setError(error.path, {
                        type: 'manual',
                        message: error.messages[0],
                    });
                });
                scrollToFirstErrors();
            }
            if ((createBookingPayment === null || createBookingPayment === void 0 ? void 0 : createBookingPayment.__typename) === 'PaymentSuspended') {
                // TODO add alert message
            }
        },
    }), createPaymentResult = _l[0], loading = _l[1].loading;
    var _m = useCheckBookingStatusLazyQuery({
        fetchPolicy: 'network-only',
        onCompleted: function (_a) {
            var checkBookingStatus = _a.checkBookingStatus;
            if ((checkBookingStatus === null || checkBookingStatus === void 0 ? void 0 : checkBookingStatus.__typename) === 'CheckBookingResult') {
                if (checkBookingStatus.status === 'NORMAL') {
                    setShowChangePriceAlert(false);
                    setShowChangeBookingDataAlert(false);
                    var formValues = methods.getValues();
                    createPaymentResult({
                        variables: {
                            bookingId: formValues.bookingId,
                            methodHandle: formValues.methodHandle,
                            input: {
                                currency: formValues.currency,
                                gatewayId: formValues.gatewayId,
                                //@ts-ignore
                                customer: formValues.customer,
                            },
                        },
                    });
                }
                else if (checkBookingStatus.status === 'PRICE_CHANGE') {
                    setShowChangePriceAlert(true);
                }
                else if (checkBookingStatus.status === 'TKT_TIME_CHANGE' ||
                    checkBookingStatus.status === 'CANCELLED') {
                    setShowChangeBookingDataAlert(true);
                }
            }
        },
    }), checkBookingStatusQuery = _m[0], checkBookingStatus = _m[1];
    var checkStatus = get(checkBookingStatus.data, 'checkBookingStatus');
    var _o = useState(false), showChangePriceAlert = _o[0], setShowChangePriceAlert = _o[1];
    var _p = useState(false), showChangeBookingDataAlert = _p[0], setShowChangeBookingDataAlert = _p[1];
    var onSubmit = function (formValues) {
        try {
            localStorage.setItem(configBillingFormDataCustomer, JSON.stringify(formValues.customer));
        }
        catch (e) { }
        checkBookingStatusQuery({ variables: { bookingUuid: bookingId } });
    };
    var summaryDescription = function (descriptions) {
        var newDescriptions = descriptions.filter(function (desc) { return desc.currencyCode === watchCurrency; });
        if (newDescriptions.length === 0) {
            newDescriptions = descriptions.filter(function (desc) { return desc.currencyCode === '*'; });
        }
        return newDescriptions;
    };
    var t = useTranslation();
    var locale = useLocaleId();
    var termsLabel = (React.createElement(React.Fragment, null,
        t('checkboxes.accept'),
        ' ',
        React.createElement("a", { href: "/" + locale + "/terms-and-conditions", target: "_blank", rel: "noreferrer" },
            ' ',
            t('checkboxes.TermsURL'),
            ' '),
        t('common.and'),
        ' ',
        React.createElement("a", { href: "/" + locale + "/privacy", target: "_blank", rel: "noreferrer" }, t('checkboxes.personalDataSynonym')),
        "."));
    useEffect(function () {
        scrollToFirstErrors();
    }, [methods.formState.submitCount]);
    return (React.createElement(FormProvider, __assign({}, methods),
        (payments === null || payments === void 0 ? void 0 : payments.billingDataRequired) && React.createElement(BillingData, { bookingId: bookingId }),
        React.createElement(Stack, null,
            React.createElement(Card, { sx: {
                    my: 2,
                    width: '100%',
                    p: 2,
                } },
                React.createElement(Stack, { sx: { mb: 1 } },
                    React.createElement(Typography, { variant: "h3", fontSize: "16px" }, t('payment.payment')),
                    React.createElement(Typography, { variant: "body1", fontSize: "12px" }, t('payment.selectMethodAndCurrency'))),
                React.createElement(Stack, null,
                    React.createElement("input", __assign({}, methods.register('bookingId'), { type: "hidden" })),
                    React.createElement(Controller, { name: "methodHandle", control: methods.control, rules: {
                            required: true,
                        }, render: function (_a) {
                            var field = _a.field, fieldState = _a.fieldState, formState = _a.formState;
                            return (React.createElement(FormControl, __assign({}, field, { component: "fieldset" }),
                                React.createElement(Stack, { direction: "row", sx: { p: 1, pl: 0, cursor: 'pointer' } }, aggregatedCurrencies.map(function (ac, index) { return (React.createElement(Controller, { key: index, control: methods.control, name: "currency", render: function (_a) {
                                        var _b = _a.field, onChange = _b.onChange, value = _b.value;
                                        return (React.createElement(Box, { component: "div", sx: { display: 'inline' }, key: index },
                                            React.createElement(Button, { sx: { maxWidth: '74px', height: '40px', mr: 2 }, variant: watchCurrency === ac.code
                                                    ? 'contained'
                                                    : 'outlined', fullWidth: true, onClick: function () { return onChange(ac.code); } }, ac.code)));
                                    } })); })),
                                fieldState.error && (React.createElement(ErrorHelperText, { sx: { mt: 2 } }, t('payment.selectMethod'))),
                                payments.methods.map(function (method, index) {
                                    return (React.createElement(PaymentMethod, { key: index, paymentButtonRef: payButtonRef, method: __assign(__assign({}, method), { icon: iconsMap[method.methodHandle] }), baseCurrency: payments.baseCurrency, currencies: payments.currencies }));
                                })));
                        } })),
                promoFareWarning && (React.createElement(Typography, { variant: "body2", mt: 1 }, promoFareWarning))),
            selectedMethods.map(function (mth, index) { return (React.createElement(Stack, { key: index },
                mth.alerts &&
                    mth.alerts.map(function (alert, index) { return (React.createElement(Alert, { key: index, 
                        // icon={<InfoOutline />}
                        sx: {
                            mt: 4,
                            py: 2,
                            px: '1.75rem',
                            mx: { xs: 2, md: 'unset' },
                        }, severity: alert.type.toLowerCase() },
                        React.createElement(Typography, { dangerouslySetInnerHTML: { __html: alert === null || alert === void 0 ? void 0 : alert.body }, variant: "body2", color: "#1B1464" }))); }),
                mth.howPay && React.createElement(HowPay, { howPay: mth.howPay }))); }),
            !isMobile ? (React.createElement(Stack, null,
                React.createElement(Typography, { variant: "body2" }, selectedMethods.map(function (mth, index) { return (React.createElement(Stack, { key: index }, mth.summaryDescription &&
                    summaryDescription(mth.summaryDescription).map(function (desc, index) { return (React.createElement(Stack, { key: index, sx: {
                            mt: 2,
                        } },
                        React.createElement("div", { dangerouslySetInnerHTML: {
                                __html: desc.description,
                            } }))); }))); })))) : null,
            React.createElement(Card, { sx: {
                    my: 2,
                    width: '100%',
                    p: 2,
                } }, isMobile ? (React.createElement(Stack, null,
                React.createElement(Stack, { direction: "row", alignItems: "center" },
                    React.createElement(FormatListBulleted, null),
                    React.createElement(Typography, { variant: "h5", sx: { pl: 1 } }, t('common.summary'))),
                React.createElement(Typography, { sx: { my: 2 } },
                    t('payment.flightFees'),
                    ":"), (_b = price.details) === null || _b === void 0 ? void 0 :
                _b.map(function (p) {
                    var _a, _b, _c, _d;
                    return (React.createElement(Stack, { sx: { my: 1 } },
                        React.createElement(Stack, null, (p === null || p === void 0 ? void 0 : p.type) === 'promo' ? (React.createElement(Stack, { justifyContent: "space-between", direction: "row" },
                            React.createElement(Box, null, "" + t("summary." + (p === null || p === void 0 ? void 0 : p.type))),
                            React.createElement(Box, null,
                                "-\u20AC", (_b = (_a = p === null || p === void 0 ? void 0 : p.total) === null || _a === void 0 ? void 0 : _a.amount) === null || _b === void 0 ? void 0 :
                                _b.replace('-', '')))) : (React.createElement(Stack, { justifyContent: "space-between", direction: "row" },
                            React.createElement(Box, null, "" + ((p === null || p === void 0 ? void 0 : p.num) === 0 ? '' : (p === null || p === void 0 ? void 0 : p.num) + " x ") + t("summary." + (p === null || p === void 0 ? void 0 : p.type))),
                            React.createElement(Box, null, ((_c = p === null || p === void 0 ? void 0 : p.total) === null || _c === void 0 ? void 0 : _c.amount) === '0'
                                ? t('summary.free')
                                : "\u20AC" + ((_d = p === null || p === void 0 ? void 0 : p.total) === null || _d === void 0 ? void 0 : _d.amount)))))));
                }),
                React.createElement(Divider, { sx: { my: 1 } }),
                React.createElement(Stack, { direction: "row", justifyContent: "flex-end", alignItems: "flex-end" },
                    React.createElement(PaymentSummary, null),
                    React.createElement(Typography, { variant: "body1" },
                        t('booking.totalPrice'),
                        ":"),
                    React.createElement(Typography, { variant: "h4", sx: { pl: 1, lineHeight: '24px' } }, watchCurrency === 'RON'
                        ? payments.currencies[0].currency.code + " " + payments.currencies[0].total.amount
                        : "" + getCurrencySymbol(payments.baseCurrency.code) + ((_c = price.total) === null || _c === void 0 ? void 0 : _c.amount))))) : (React.createElement(Stack, null,
                React.createElement(Stack, { direction: "row" },
                    React.createElement(FormatListBulleted, null),
                    React.createElement(Typography, { sx: { ml: 1, fontWeight: 'bold' } }, t('common.summary'))),
                React.createElement(Stack, null,
                    React.createElement(Typography, { variant: "body1", sx: {
                            my: 2,
                            fontWeight: 'bold',
                            fontSize: '14px',
                        } },
                        t('payment.flightFees'),
                        ":"), (_d = price.details) === null || _d === void 0 ? void 0 :
                    _d.map(function (p) {
                        var _a, _b, _c, _d;
                        return (React.createElement(Stack, { sx: { my: 0.5, width: '100%' } },
                            React.createElement(Stack, null, (p === null || p === void 0 ? void 0 : p.type) === 'promo' ? (React.createElement(Stack, { justifyContent: "space-between", direction: "row" },
                                React.createElement(Box, null, "" + t("summary." + (p === null || p === void 0 ? void 0 : p.type))),
                                React.createElement(Box, null,
                                    "-\u20AC", (_b = (_a = p === null || p === void 0 ? void 0 : p.total) === null || _a === void 0 ? void 0 : _a.amount) === null || _b === void 0 ? void 0 :
                                    _b.replace('-', '')))) : (React.createElement(Stack, { justifyContent: "space-between", direction: "row" },
                                React.createElement(Box, null, "" + ((p === null || p === void 0 ? void 0 : p.num) === 0 ? '' : (p === null || p === void 0 ? void 0 : p.num) + " x ") + t("summary." + (p === null || p === void 0 ? void 0 : p.type))),
                                React.createElement(Box, null, ((_c = p === null || p === void 0 ? void 0 : p.total) === null || _c === void 0 ? void 0 : _c.amount) === '0'
                                    ? t('summary.free')
                                    : "\u20AC" + ((_d = p === null || p === void 0 ? void 0 : p.total) === null || _d === void 0 ? void 0 : _d.amount)))))));
                    }),
                    React.createElement(Stack, { direction: "row", justifyContent: "flex-end", alignItems: 'flex-end' },
                        React.createElement(PaymentSummary, null),
                        React.createElement(Typography, { variant: "h4", sx: { lineHeight: '24px' } }, t('booking.totalPrice')),
                        React.createElement(Typography, { variant: "h2", sx: { pl: 1, lineHeight: '1' } }, watchCurrency === 'RON'
                            ? payments.currencies[0].currency.code + " " + payments.currencies[0].total.amount
                            : "" + (payments.baseCurrency.code === 'EUR' && '€') + ((_e = price.total) === null || _e === void 0 ? void 0 : _e.amount))))))),
            isMobile && showVoucher && React.createElement(Voucher, { bookingId: bookingId }),
            React.createElement(Controller, { name: "terms", rules: {
                    required: t('required.field'),
                }, control: methods.control, render: function (_a) {
                    var field = _a.field, fieldState = _a.fieldState, formState = _a.formState;
                    return (React.createElement(FormControl, __assign({ fullWidth: true }, field, { error: !!fieldState.error, sx: __assign({ fontSize: '14px', margin: '15px 0 30px 0' }, (!!fieldState.error && {
                            border: '1px solid #ffd5d5',
                            borderRadius: '8px',
                            padding: '8px 12px',
                            background: '#fff4f4',
                        })) }),
                        React.createElement(FormControlLabel, __assign({}, field, { control: React.createElement(Checkbox, __assign({}, field, { checked: field.value, sx: __assign({}, (!!fieldState.error && {
                                    '& .MuiSvgIcon-root': {
                                        color: theme.palette.error.main,
                                    },
                                })), onChange: function (e) {
                                    field.onChange(e.target.checked);
                                } })), label: termsLabel }))));
                } }),
            showChangePriceAlert &&
                checkStatus &&
                checkStatus.__typename === 'CheckBookingResult' && (React.createElement(Dialog, { open: showChangePriceAlert, onClose: function () { } },
                React.createElement(Alert, { sx: {
                        mt: 4,
                        py: 2,
                        px: '1.75rem',
                    }, severity: "warning" },
                    checkStatus.alerts && ((_f = checkStatus.alerts[0]) === null || _f === void 0 ? void 0 : _f.title) && (React.createElement(AlertTitle, null, checkStatus.alerts[0].title)),
                    checkStatus.alerts && checkStatus.alerts.length > 0 && (React.createElement(Typography, { variant: "body2", color: "#15B9B9" }, (_g = checkStatus.alerts[0]) === null || _g === void 0 ? void 0 : _g.body)),
                    acceptPriceChangeResult.loading ? (React.createElement(Stack, { justifyContent: "center", alignItems: "center" },
                        React.createElement(CircularProgress, null))) : (React.createElement(Stack, { direction: "row", justifyContent: "center", alignItems: "center", spacing: 2 },
                        React.createElement(Button, { variant: "contained", color: "error", onClick: function () { return handleDeclinePriceChange(); } }, t('common.cancel')),
                        React.createElement(Button, { onClick: function () { return handleAcceptPriceChangeClick(); }, color: "primary", variant: "contained" }, t('common.accept'))))))),
            showChangeBookingDataAlert &&
                checkStatus &&
                checkStatus.__typename === 'CheckBookingResult' && (React.createElement(Dialog, { open: showChangeBookingDataAlert, onClose: function () { } },
                React.createElement(Alert, { sx: {
                        mt: 4,
                        py: 2,
                        px: '1.75rem',
                    }, severity: "warning" },
                    checkStatus.alerts && ((_h = checkStatus.alerts[0]) === null || _h === void 0 ? void 0 : _h.title) && (React.createElement(AlertTitle, null, checkStatus.alerts[0].title)),
                    checkStatus.alerts && checkStatus.alerts.length > 0 && (React.createElement(Typography, { variant: "body2", color: "#15B9B9" }, (_j = checkStatus.alerts[0]) === null || _j === void 0 ? void 0 : _j.body)),
                    React.createElement(Stack, { direction: "row" },
                        React.createElement(Button, { onClick: function () {
                                refetch();
                                setShowChangeBookingDataAlert(false);
                            } }, "OK"))))),
            React.createElement(Stack, { ref: payButtonRef },
                React.createElement(Button, { type: "submit", variant: "contained", sx: {
                        width: { xs: 'unset' },
                        maxWidth: { lg: 375 },
                        height: '48px',
                        alignSelf: { lg: 'flex-end', xs: 'unset' },
                        color: 'primary.contrastText',
                    }, disabled: methods.formState.isSubmitting || loading, color: "error", onClick: function (e) { return methods.handleSubmit(onSubmit)(e); } }, loading ? (React.createElement(CircularProgress, null)) : (React.createElement(Box, { sx: {
                        display: 'flex',
                        alignItems: 'center',
                    } },
                    React.createElement(ArrowForward, { viewBox: "-9 -7 24 24" }),
                    React.createElement(Box, { sx: { maxWidth: 'calc(100% - 24px)' } }, watchGatewayName === 'bank_transfer'
                        ? t('payment.printCheck')
                        : t('payment.pay')))))),
            React.createElement(Stack, { sx: {
                    mt: '15px',
                    fontSize: '12px',
                    color: '#555',
                } }, isMobile ? (React.createElement(Stack, null, selectedMethods.map(function (mth, index) { return (React.createElement(Stack, { key: index }, mth.summaryDescription &&
                summaryDescription(mth.summaryDescription).map(function (desc, index) { return (React.createElement(Stack, { key: index, sx: {
                        mt: 2,
                    } },
                    React.createElement("div", { dangerouslySetInnerHTML: {
                            __html: desc.description,
                        } }))); }))); }))) : null))));
};
export default Payment;
