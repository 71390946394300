import React, { useContext, useEffect, useState } from 'react';
import { Link as MUILink, Stack, Typography, Box } from '@material-ui/core';
import useTranslation, { useLocaleId } from '../../hooks/useTranslation';
import User from '../User/User';
import { AuthContext } from 'app/hooks/useAuth';
import logo from './site-logo.svg';
import whiteLogo from './newLogo.svg';
import LocaleMenu from './LocaleMenu';
import { configSiteName } from '../../util/siteConfig';
var DesktopMenu = function (_a) {
    var paletteMode = _a.paletteMode, _b = _a.navigationType, navigationType = _b === void 0 ? 'links' : _b;
    var t = useTranslation();
    var locale = useLocaleId();
    var context = useContext(AuthContext);
    var _c = useState(false), isAuthenticated = _c[0], setIsAutheticated = _c[1];
    var ticketMenuItems = [
        {
            label: 'menuButtons.tickets',
            link: '/',
            iconSrc: require('../../assets/icons/Airport.svg'),
        },
        {
            label: 'menuButtons.groupOffer',
            link: "/" + locale + "/group-offer",
            iconSrc: require('../../assets/icons/transfer.svg'),
        },
        {
            label: 'menuButtons.corporate',
            link: "/" + locale + "/corporate",
            iconSrc: require('../../assets/icons/corporateOffers.svg'),
        },
        // {
        //   label: 'Chartere',
        //   link: '',
        //   iconSrc: require('../../assets/icons/Charter.svg'),
        // },
        // {
        //   label: 'Asigurari',
        //   link: 'https://www.asigurari.ro',
        //   iconSrc: require('../../assets/icons/Insurance.svg'),
        // },
    ];
    useEffect(function () {
        if (context.authData.user) {
            setIsAutheticated(true);
        }
        else {
            setIsAutheticated(false);
        }
    }, [context.authData.user]);
    return (React.createElement(React.Fragment, null,
        React.createElement(Stack, { spacing: 3, direction: "row", sx: {
                alignItems: 'center',
                py: 2,
            } },
            React.createElement("a", { href: "/" },
                React.createElement(Box, { component: 'img', src: paletteMode === 'dark' ? whiteLogo : logo, title: configSiteName + " - rezerv\u0103ri bilete avion \u0219i hoteluri online", alt: configSiteName, sx: { width: '94px', margin: '0 8px 0 0' } })),
            React.createElement(Stack, { direction: "row", sx: { gap: '24px' } }, ticketMenuItems.map(function (_a, key) {
                var label = _a.label, link = _a.link;
                return (React.createElement(MUILink, { key: key, href: link, target: "_blank", sx: {
                        textDecoration: 'none',
                        color: paletteMode === 'dark' ? '#fff' : 'text.primary',
                    } }, t(label)));
            })),
            React.createElement("div", { style: { flex: 1 } }),
            React.createElement(LocaleMenu, { paletteMode: paletteMode }),
            React.createElement(Typography, { component: MUILink, href: "/" + locale + "/contacts", sx: {
                    textDecorationLine: 'none',
                    color: paletteMode === 'dark' ? '#fff' : 'text.primary',
                }, color: "text.primary" }, t('menuButtons.contacts')),
            React.createElement(User, { paletteMode: paletteMode, isAuthenticated: isAuthenticated }))));
};
export default DesktopMenu;
