import React, { useContext, useEffect, useState } from 'react';
import { Divider, Drawer, IconButton, Stack, Typography, Box, } from '@material-ui/core';
import whiteLogo from './newLogo.svg';
import rightArrowIcon from './right-arrow-list.svg';
import Menu from '../../assets/icons/Menu';
import AccountCircle from './icons/acount-cricle.svg';
import Clear from '../../assets/icons/Clear';
import { AuthContext } from 'app/hooks/useAuth';
import User from '../User/User';
import useTranslation, { useLocaleId } from '../../hooks/useTranslation';
import MobileNavigation from './MobileNavigation';
import LocaleMenu from './LocaleMenu';
import { IconSCTwo } from '../FooterSection/styles';
// import linkedinIcon from '../FooterSection/icons/socials/Icon1.svg';
import facebookIcon from '../FooterSection/icons/socials/Icon2.svg';
// import telegramIcon from '../FooterSection/icons/socials/Icon3.svg';
// import twitterIcon from '../FooterSection/icons/socials/Icon4.svg';
import instagramIcon from '../FooterSection/icons/socials/Icon5.svg';
import { configSiteName, configSiteUrl, configFacebookUrl, configInstagramUrl, } from '../../util/siteConfig';
var MobileMenu = function (_a) {
    var paletteMode = _a.paletteMode, _b = _a.navVisible, navVisible = _b === void 0 ? false : _b;
    var _c = useState(false), menuDrawerVisible = _c[0], setMenuDrawerVisible = _c[1];
    var _d = useState(false), userDrawerVisible = _d[0], setUserDrawerVisible = _d[1];
    var t = useTranslation();
    var locale = useLocaleId();
    var ticketMenuItems = [
        {
            label: 'menuButtons.tickets',
            link: '/',
            iconSrc: require('../../assets/icons/Airport.svg'),
        },
        {
            label: 'menuButtons.groupOffer',
            link: "/" + locale + "/group-offer",
            iconSrc: require('../../assets/icons/transfer.svg'),
        },
        {
            label: 'menuButtons.corporate',
            link: "/" + locale + "/corporate",
            iconSrc: require('../../assets/icons/corporateOffers.svg'),
        },
        // {
        //   label: 'Chartere',
        //   link: '',
        //   iconSrc: require('../../assets/icons/Charter.svg'),
        // },
        // {
        //   label: 'Asigurari',
        //   link: 'https://www.asigurari.ro',
        //   iconSrc: require('../../assets/icons/Insurance.svg'),
        // },
    ];
    var LeftMenuItems = [
        {
            item: configSiteName,
            children: [
                {
                    label: t('menuButtons.about'),
                    href: "/" + locale + "/about",
                },
                {
                    label: t('menuButtons.contacts'),
                    href: "/" + locale + "/contacts",
                },
                {
                    label: t('footer.bonus'),
                    href: "/" + locale + "/bonus-program",
                },
                {
                    label: t('footer.faq'),
                    href: "/" + locale + "/faq",
                },
                {
                    label: t('footer.whatsCheckin'),
                    href: "/" + locale + "/check-in",
                },
                // {
                //   label: t('footer.checkin'),
                //   href: `/${locale}/check-in-online`,
                // },
                {
                    label: t('footer.groupOffer'),
                    href: "/" + locale + "/group-offer",
                },
                {
                    label: t('footer.corporate'),
                    href: "/" + locale + "/corporate",
                },
                {
                    label: t('businessClass.heroSection.title'),
                    href: "/" + locale + "/business-class",
                },
                {
                    label: t('footer.blog'),
                    href: "/" + locale + "/blog",
                },
            ],
            state: true,
        },
        {
            item: t('menuButtons.terms'),
            children: [
                {
                    label: t('menuButtons.terms'),
                    href: "/" + locale + "/terms-and-conditions",
                },
                {
                    label: t('footer.privacy'),
                    href: "/" + locale + "/privacy",
                },
                {
                    label: t('cookie.title'),
                    href: "/" + locale + "/cookie-policy",
                },
                {
                    label: t('footer.iata'),
                    href: "/" + locale + "/iata",
                },
                {
                    label: t('footer.specialNeeds'),
                    href: "/" + locale + "/special-needs",
                },
            ],
            state: true,
        },
    ];
    var context = useContext(AuthContext);
    var _e = useState(false), isAuthenticated = _e[0], setIsAutheticated = _e[1];
    useEffect(function () {
        if (context.authData.user) {
            setIsAutheticated(true);
        }
        else {
            setIsAutheticated(false);
        }
    }, [context.authData.user]);
    var closeDrawer = function () { return setUserDrawerVisible(false); };
    return (React.createElement(React.Fragment, null,
        React.createElement(Stack, { direction: "row", width: "100%", py: 2, sx: { alignItems: 'center', justifyContent: 'space-between' } },
            React.createElement(IconButton, { onClick: function () { return setMenuDrawerVisible(true); }, sx: { p: 0, margin: { xs: '0 40px 0 0', md: '0' } } },
                React.createElement(Menu, { viewBox: "-4 -5 24 24" })),
            React.createElement("a", { href: "/", style: { display: 'flex' } },
                React.createElement(Box, { component: "img", src: whiteLogo, title: configSiteName + " - " + t('home.compare.prices'), alt: configSiteName, sx: { width: '92px' } })),
            React.createElement(Box, { sx: { display: 'flex', alignItems: 'center', gap: '8px' } },
                React.createElement(LocaleMenu, { paletteMode: paletteMode }),
                React.createElement(IconButton, { onClick: function () { return setUserDrawerVisible(true); }, sx: {
                        width: '26px',
                        height: '26px',
                    } },
                    React.createElement("img", { src: AccountCircle })))),
        navVisible && React.createElement(MobileNavigation, null),
        React.createElement(Drawer, { anchor: 'left', open: menuDrawerVisible, onClose: function () { return setMenuDrawerVisible(false); }, PaperProps: {
                sx: {
                    backgroundColor: 'primary.dark',
                    color: 'common.white',
                    right: '60px',
                },
            }, sx: {
                zIndex: 1300,
            } },
            React.createElement(Stack, { sx: { p: 3 }, direction: "row", justifyContent: "space-between", alignItems: "center" },
                React.createElement("img", { src: whiteLogo, style: { height: 46 }, alt: configSiteName }),
                React.createElement(Clear, { viewBox: "-2 -2 16 16", onClick: function () { return setMenuDrawerVisible(false); } })),
            React.createElement(Divider, { variant: "fullWidth", style: { background: '#D3DAE4' } }),
            React.createElement(Stack, { direction: "column", sx: { pl: 2, py: 3 } },
                React.createElement(Typography, { variant: "h6", sx: { pl: 1, pb: 1 } }, t('footer.services')),
                ticketMenuItems.map(function (_a, key) {
                    var label = _a.label, link = _a.link;
                    return (React.createElement(Stack, { direction: "row", gap: "7px", alignItems: "center", fontSize: "14px", sx: { pl: 3, pt: '5px', pr: '10px' }, key: key },
                        React.createElement("img", { src: rightArrowIcon, style: { height: 21 } }),
                        React.createElement("a", { href: link, style: { textDecoration: 'none', color: '#fff' } }, t(label))));
                })),
            React.createElement(Divider, { variant: "fullWidth", style: { background: '#D3DAE4' } }),
            React.createElement(Stack, null, LeftMenuItems.map(function (elm, key) { return (React.createElement(React.Fragment, { key: key },
                React.createElement(Stack, { direction: "column", sx: { pl: 2, py: 3 } },
                    React.createElement(Typography, { variant: "h6", sx: { pl: 1, pb: 1 } }, elm.item),
                    elm.children.map(function (child, key) { return (React.createElement(Stack, { direction: "row", gap: "7px", alignItems: "center", fontSize: "14px", sx: { pl: 3, pt: '5px', pr: '10px' }, key: key },
                        React.createElement("img", { src: rightArrowIcon, style: { height: 21 } }),
                        React.createElement("a", { href: child.href, style: { textDecoration: 'none', color: '#fff' } }, child.label))); })),
                React.createElement(Divider, { variant: "fullWidth", style: { background: '#D3DAE4' } }))); })),
            React.createElement(Stack, { direction: "row", justifyContent: "center", alignItems: "center", spacing: 2, sx: { py: 3 } },
                React.createElement("a", { href: "mailto:" + configSiteUrl, style: { fontSize: 20, color: '#fff', textDecoration: 'none' } }, configSiteUrl)),
            React.createElement(Divider, { variant: "fullWidth", style: { background: '#D3DAE4' } }),
            React.createElement(Stack, { direction: "row", gap: "12px", justifyContent: "center", alignItems: "center", sx: { py: 3 } },
                React.createElement("a", { href: configFacebookUrl, target: "_blank", rel: "noreferrer" },
                    React.createElement(IconSCTwo, { style: { height: 32 }, src: facebookIcon })),
                React.createElement("a", { href: configInstagramUrl, target: "_blank", rel: "noreferrer" },
                    React.createElement(IconSCTwo, { style: { height: 32 }, src: instagramIcon })))),
        React.createElement(Drawer, { anchor: 'right', sx: { zIndex: 1300 }, open: userDrawerVisible, onClose: function () { return setUserDrawerVisible(false); } },
            React.createElement(User, { isAuthenticated: isAuthenticated, mobileOpen: userDrawerVisible, closeMobileDrawer: closeDrawer }))));
};
export default MobileMenu;
