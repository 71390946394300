import { Button, Stack, Typography, Tooltip, useMediaQuery, } from '@material-ui/core';
import React, { useState, useRef, useEffect } from 'react';
import ScrollBooster from 'scrollbooster';
import { AirportSearchResultContainer, AirportSelectInputWrapper, } from './elements';
import Cancel from 'app/assets/icons/Cancel';
import useTranslation from 'app/hooks/useTranslation';
export var AirportInput = function (_a) {
    var label = _a.label, wide = _a.wide, value = _a.value, onChange = _a.onChange, inputText = _a.inputText, onInputTextChange = _a.onInputTextChange, _b = _a.onMouseDown, onMouseDown = _b === void 0 ? function (e) { } : _b, _c = _a.onFocus, onFocus = _c === void 0 ? function () { } : _c, isOpen = _a.isOpen, autoFocus = _a.autoFocus, flightError = _a.flightError, type = _a.type, placeholder = _a.placeholder;
    var t = useTranslation();
    var scrollableContainerRef = useRef(null);
    var boxRef = useRef(null);
    var scrollBooster = useRef(null);
    var _d = useState(flightError), isTooltipOpened = _d[0], setTooltipOpened = _d[1];
    var isMobile = useMediaQuery(function (theme) { return theme.breakpoints.down('sm'); });
    useEffect(function () {
        if (scrollableContainerRef.current && boxRef.current) {
            if (scrollBooster.current) {
                scrollBooster.current.updateMetrics();
            }
            else {
                scrollBooster.current = new ScrollBooster({
                    viewport: scrollableContainerRef.current,
                    scrollMode: 'transform',
                    direction: 'horizontal',
                    emulateScroll: true,
                    inputsFocus: false,
                });
            }
            scrollBooster.current.scrollTo({
                x: boxRef.current.clientWidth -
                    scrollableContainerRef.current.clientWidth,
            });
        }
    }, [value]);
    useEffect(function () {
        var interval = setInterval(function () { return setTooltipOpened(false); }, 3000);
        return function () {
            clearInterval(interval);
        };
    }, [isTooltipOpened]);
    var inputPlaceholderHandler = function () {
        if (isMobile && value.length === 1) {
            return '';
        }
        else if (value.length === 0) {
            return placeholder;
        }
    };
    return (React.createElement(AirportSelectInputWrapper, { wide: wide, open: isOpen, flightError: flightError },
        React.createElement(Typography, { onClick: onFocus, variant: "body2", color: isMobile ? 'text.primary' : 'text.secondary', pl: "11px", pr: 1, sx: { fontSize: '14px' } }, label),
        React.createElement(AirportSearchResultContainer, { ref: scrollableContainerRef },
            React.createElement(Stack, { ref: boxRef, direction: "row", spacing: 0.5 }, value.map(function (selectedItem, index) { return (React.createElement(Button, { variant: "contained", key: "selected-item-" + index, sx: {
                    whiteSpace: 'nowrap',
                    p: '6px 8px',
                    backgroundColor: 'tertiary.dark',
                }, endIcon: React.createElement(Cancel, { viewBox: "0 -3 20 20", sx: { stroke: 'white' }, onClick: function (e) {
                        e.stopPropagation();
                        onChange(value.filter(function (v) { return v.code !== selectedItem.code; }));
                    } }) }, selectedItem.name)); }))),
        React.createElement(Tooltip, { open: isTooltipOpened, arrow: true, onClose: function () { return setTooltipOpened(false); }, title: label, placement: "bottom-start", classes: {
                tooltip: 'with-border',
            }, disableFocusListener: true, disableHoverListener: true, disableTouchListener: true },
            React.createElement("input", { value: inputText, onMouseDown: onMouseDown, onChange: function (e) { return onInputTextChange(e.target.value); }, onFocus: onFocus, autoFocus: autoFocus, placeholder: inputPlaceholderHandler() }))));
};
