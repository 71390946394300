import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Stack, SwipeableDrawer, Tab, Typography, useMediaQuery, } from '@material-ui/core';
import { Box } from '@material-ui/system';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useTranslation, { useLocaleId } from '../../../hooks/useTranslation';
import { activeFlightClassSelector, passengerAges, passengerCountStateSelector, setFlightClass, totalPassengerCountSelector, setInitialPassengerCount, updatePassengerCount, } from '../../../redux/slices/flightSearch.slice';
import { PristineButton } from '../../ui/buttons';
import { PassengerAgesDescription, PassengerCounterButtons, PassengerCounterButtonsGroupOffer, PassengerCountItem, PassengerCountItemGroupOffer, PassengerCountList, PassengerCountMenuAnchor, PassengerCountRoot, PassengerCountToggle, } from './elements';
import { Clear } from '@material-ui/icons';
import ExpandMoreFilters from '../../../assets/icons/ExpandMoreFilters';
import { useHistory } from 'react-router-dom';
import ArrowForward from 'app/assets/icons/ArrowForward';
var PassengerCountSelect = function () {
    var _a = useState(false), isOpen = _a[0], setIsOpen = _a[1];
    var isMobile = useMediaQuery(function (theme) { return theme.breakpoints.down('sm'); });
    var dispatch = useDispatch();
    var t = useTranslation();
    var locale = useLocaleId();
    var history = useHistory();
    var totalPassengerCount = useSelector(totalPassengerCountSelector);
    var passengerCountMap = useSelector(passengerCountStateSelector);
    var activeFlightClass = useSelector(activeFlightClassSelector);
    var maxPassengerCount = 9;
    var _b = useState({}), prevValue = _b[0], setPrevValue = _b[1];
    useEffect(function () {
        if (isOpen) {
            var callback_1 = function () {
                setIsOpen(false);
                document.removeEventListener('click', callback_1);
            };
            document.addEventListener('click', callback_1);
            return function () {
                document.removeEventListener('click', callback_1);
            };
        }
        setPrevValue({ passengerCountMap: passengerCountMap, activeFlightClass: activeFlightClass });
    }, [isOpen]);
    return (React.createElement(PassengerCountRoot, { onClick: function (e) {
            e.stopPropagation();
            e.nativeEvent.stopImmediatePropagation();
        } },
        React.createElement(PassengerCountToggle, { isOpen: isOpen, onClick: function () {
                setIsOpen(!isOpen);
            } },
            React.createElement(Stack, { direction: "row", sx: {
                    alignItems: 'center',
                    whiteSpace: 'nowrap',
                } },
                React.createElement(Typography, { variant: "body2", color: "text.primary" },
                    totalPassengerCount,
                    ' ',
                    totalPassengerCount % 10 > 1
                        ? t('common.passengers').toLowerCase()
                        : t('common.passenger').toLowerCase()),
                isMobile && (React.createElement(Typography, { variant: "body2", color: "text.primary" },
                    ", ",
                    t("flightClass." + activeFlightClass).toLowerCase()))),
            React.createElement(ExpandMoreFilters, { viewBox: "-5 -5 15 15", sx: { pr: 1 } })),
        React.createElement(PassengerCountMenuAnchor, null, isOpen ? (isMobile ? (React.createElement(SwipeableDrawer, { anchor: "bottom", open: isOpen, onOpen: function () { return setIsOpen(true); }, onClose: function () { return setIsOpen(false); } },
            React.createElement(Stack, { justifyContent: "space-between", sx: { height: '80vh', pb: 0.5 } },
                React.createElement(Stack, { justifyContent: "space-between", alignItems: "center", sx: {
                        backgroundColor: 'text.primary',
                    } },
                    React.createElement(Stack, { direction: "row", justifyContent: "space-between", alignItems: "center", sx: { width: '100%', padding: '16px 16px' } },
                        React.createElement(Typography, { sx: {
                                maxWidth: 'calc(100% - 48px)',
                                fontWeight: 600,
                                fontSize: '1em',
                                color: 'common.white',
                            } }, t('searchForm.label.pickPassengersAndFlightClass')),
                        React.createElement(Clear, { onClick: function () { return setIsOpen(false); }, sx: { color: 'common.white' } })),
                    React.createElement(Box, { sx: {
                            width: '100%',
                            backgroundColor: 'common.white',
                            height: '35px',
                            borderRadius: '12px 12px 0 0',
                            display: 'flex',
                            justifyContent: 'center',
                        } },
                        React.createElement("div", { style: {
                                width: '30px',
                                height: '4px',
                                backgroundColor: 'grey',
                                marginTop: '8px',
                                borderRadius: 21,
                            } }))),
                React.createElement(PassengerCountList, null,
                    passengerAges.map(function (pa) { return (React.createElement(PassengerCountItem, { key: pa },
                        React.createElement("div", { onClick: function () { return setIsOpen(false); } },
                            t("passengerAges." + pa),
                            React.createElement(PassengerAgesDescription, null, t("passengerAgesDescription." + pa))),
                        React.createElement(PassengerCounterButtons, null,
                            React.createElement(PristineButton, { disabled: pa === 'adult'
                                    ? passengerCountMap[pa] === 1
                                    : passengerCountMap[pa] === 0, onClick: function () {
                                    return dispatch(updatePassengerCount({
                                        age: pa,
                                        direction: 'down',
                                    }));
                                } },
                                React.createElement(FontAwesomeIcon, { icon: faMinus })),
                            React.createElement("div", null, passengerCountMap[pa]),
                            React.createElement(PristineButton, { disabled: pa === 'infant'
                                    ? passengerCountMap.infant >=
                                        passengerCountMap.adult
                                    : passengerCountMap.adult +
                                        passengerCountMap.child ===
                                        maxPassengerCount, onClick: function () {
                                    return dispatch(updatePassengerCount({
                                        age: pa,
                                        direction: 'up',
                                    }));
                                } },
                                React.createElement(FontAwesomeIcon, { icon: faPlus }))))); }),
                    React.createElement(PassengerCountItemGroupOffer, null,
                        React.createElement("div", { style: { marginBottom: '10px' } },
                            t('footer.groupOffer'),
                            React.createElement(PassengerAgesDescription, null, t('groupOfferHint'))),
                        React.createElement(PassengerCounterButtonsGroupOffer, null,
                            React.createElement(Button, { sx: {
                                    width: '100% !important',
                                    paddingLeft: '0',
                                    '&:hover': {
                                        backgroundColor: 'primary.main',
                                    },
                                }, onClick: function () {
                                    history.push("/" + locale + "/group-offer");
                                } },
                                React.createElement(ArrowForward, { viewBox: "-9 -7 24 24" }),
                                t('flightCard.details')))),
                    React.createElement(Stack, { direction: "row", justifyContent: "center", sx: {
                            minHeight: 'auto',
                            my: 1,
                        } },
                        React.createElement(Tab, { onClick: function () { return dispatch(setFlightClass('economy')); }, sx: {
                                borderRadius: 8,
                                opacity: 1,
                                border: activeFlightClass === 'business'
                                    ? '1px solid #1B1464'
                                    : 'none',
                                backgroundColor: activeFlightClass === 'business'
                                    ? 'common.white'
                                    : 'text.primary',
                                color: activeFlightClass === 'business'
                                    ? 'text.primary'
                                    : 'common.white',
                                padding: '4px 16px',
                                minHeight: 'auto',
                                mr: 1,
                            }, label: t("flightClass.economy") }),
                        React.createElement(Tab, { onClick: function () { return dispatch(setFlightClass('business')); }, sx: {
                                borderRadius: 8,
                                opacity: 1,
                                border: activeFlightClass === 'economy'
                                    ? '1px solid #1B1464'
                                    : 'none',
                                backgroundColor: activeFlightClass === 'economy'
                                    ? 'common.white'
                                    : 'text.primary',
                                color: activeFlightClass === 'economy'
                                    ? 'text.primary'
                                    : 'common.white',
                                padding: '4px 16px',
                                minHeight: 'auto',
                                mr: 1,
                            }, label: t("flightClass.business") }))),
                React.createElement(Stack, { direction: "row", justifyContent: "space-between", alignItems: "center" },
                    React.createElement(Button, { sx: {
                            p: 1,
                            mx: 0.5,
                            borderRadius: '3px',
                            backgroundColor: '#f2f2f2',
                            color: 'text.primary',
                            width: '100%',
                            height: 46,
                        }, onClick: function () {
                            setIsOpen(false);
                            prevValue.activeFlightClass !== undefined &&
                                dispatch(setFlightClass(prevValue.activeFlightClass));
                            dispatch(setInitialPassengerCount(prevValue.passengerCountMap));
                        } }, t('common.cancel')),
                    React.createElement(Button, { sx: {
                            p: 1,
                            mx: 0.5,
                            borderRadius: '3px',
                            backgroundColor: 'primary.main',
                            color: 'common.white',
                            width: '100%',
                            height: 46,
                        }, onClick: function () { return setIsOpen(false); } }, t('common.apply')))))) : (React.createElement(PassengerCountList, null,
            passengerAges.map(function (pa) { return (React.createElement(PassengerCountItem, { key: pa },
                React.createElement("div", { onClick: function () { return setIsOpen(false); } },
                    t("passengerAges." + pa),
                    React.createElement(PassengerAgesDescription, null, t("passengerAgesDescription." + pa))),
                React.createElement(PassengerCounterButtons, null,
                    React.createElement(PristineButton, { disabled: pa === 'adult'
                            ? passengerCountMap[pa] === 1
                            : passengerCountMap[pa] === 0, onClick: function () {
                            return dispatch(updatePassengerCount({ age: pa, direction: 'down' }));
                        } },
                        React.createElement(FontAwesomeIcon, { icon: faMinus })),
                    React.createElement("div", null, passengerCountMap[pa]),
                    React.createElement(PristineButton, { disabled: pa === 'infant'
                            ? passengerCountMap.infant >= passengerCountMap.adult
                            : passengerCountMap.adult +
                                passengerCountMap.child ===
                                maxPassengerCount, onClick: function () {
                            return dispatch(updatePassengerCount({ age: pa, direction: 'up' }));
                        } },
                        React.createElement(FontAwesomeIcon, { icon: faPlus }))))); }),
            React.createElement(PassengerCountItemGroupOffer, null,
                React.createElement("div", { style: { marginBottom: '10px' } },
                    t('footer.groupOffer'),
                    React.createElement(PassengerAgesDescription, null, t('groupOfferHint'))),
                React.createElement(PassengerCounterButtonsGroupOffer, null,
                    React.createElement(Button, { sx: {
                            paddingLeft: '0',
                            '&:hover': {
                                backgroundColor: 'primary.main',
                            },
                        }, onClick: function () {
                            history.push("/" + locale + "/group-offer");
                        } },
                        React.createElement(ArrowForward, { viewBox: "-9 -7 24 24" }),
                        t('flightCard.details'))))))) : null)));
};
export default PassengerCountSelect;
