export var configSiteName = 'Fly24.bg';
export var configSiteUrl = 'www.fly24.bg';
export var configPhone = '+40317811110'; //for hrefs
export var configPhoneMask = '+40 3178 11110'; //for templates
export var configContactEmail = 'info@fly24.bg';
export var configLegalName = 'EMA Plus Solutions S.R.L.';
export var configLegalAddress = 'Registrul Comerțului J40/19757/2017 | CUI RO38540407 Victoria Business Park, mun. București, Șoseaua București-Ploiești nr73-81, etajul 4,clădirea nr.4, biroul 20, 013685';
export var configFacebookUrl = 'https://www.facebook.com/';
export var configInstagramUrl = 'https://www.instagram.com/';
export var configUtmObjKey = 'fly24bg-utm';
export var configGdpr = 'fly24bg_gdpr';
export var configJwtTokenKey = 'fly24bg-jwt';
export var configLocale = 'fly24bg-locale';
export var configBillingFormDataCustomer = 'billing-form-data-customer';
