var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Button, Checkbox, CircularProgress, Dialog, DialogTitle, FormControl, FormControlLabel, IconButton, InputAdornment, Stack, TextField, Typography, useMediaQuery, } from '@material-ui/core';
import React, { useState, useEffect, useContext } from 'react';
import { useDispatch } from 'react-redux';
import useTranslation, { useLocaleId } from '../../hooks/useTranslation';
import { Spacer } from '../ui/spacers';
import { DialogRoot } from './elements';
import get from 'lodash.get';
import Visibility from '../../assets/icons/Visibility';
import VisibilityOff from '../../assets/icons/VisibilityOff';
import ArrowForward from '../../assets/icons/ArrowForward';
import { useCreateUserMutation, useLoginMutation, useRequestRecoveryPasswordMutation, } from '../../generated/graphql';
import Cancel from '../../assets/icons/Cancel';
import { AuthContext } from '../../hooks/useAuth';
import { Controller, useForm } from 'react-hook-form';
import SocialLogin from './SocialLogin/SocialLogin';
import { useTheme } from '@emotion/react';
import { configSiteName } from 'app/util/siteConfig';
var Login = function (_a) {
    var open = _a.open, onClose = _a.onClose, _b = _a.initialTab, initialTab = _b === void 0 ? 'signIn' : _b;
    var _c = useState(false), isOpen = _c[0], setIsOpen = _c[1];
    var isMobile = useMediaQuery(function (theme) { return theme.breakpoints.down('sm'); });
    var dispatch = useDispatch();
    var t = useTranslation();
    var locale = useLocaleId();
    var context = useContext(AuthContext);
    var defaultValues = {
        password: '',
        login: '',
        terms: false,
        dataProcessing: false,
        newsletter: false,
    };
    var methods = useForm({ defaultValues: defaultValues });
    var initialState = {
        password: '',
        login: '',
        showPassword: false,
    };
    var _d = useState(initialState), values = _d[0], setValues = _d[1];
    var _e = useState(initialTab), activeTab = _e[0], setActiveTab = _e[1];
    var responseProcessCallback = function (response) {
        var _a;
        if ((response === null || response === void 0 ? void 0 : response.__typename) === 'AuthPayload') {
            context.login(response);
        }
        else if ((response === null || response === void 0 ? void 0 : response.__typename) === 'ValidationErrors') {
            (_a = response.fields) === null || _a === void 0 ? void 0 : _a.forEach(function (f) {
                //@ts-ignore
                methods.setError(f.path, {
                    type: 'manual',
                    message: f === null || f === void 0 ? void 0 : f.messages[0],
                });
            });
        }
    };
    useEffect(function () { return setActiveTab(initialTab); }, [open]);
    var changeTabHandler = function (tab) {
        setActiveTab(tab);
        setValues(initialState);
        methods.clearErrors();
    };
    var _f = useLoginMutation({
        onCompleted: function (_a) {
            var login = _a.login;
            responseProcessCallback(login);
        },
    }), loginResult = _f[0], loginData = _f[1];
    var _g = useCreateUserMutation({
        onCompleted: function (_a) {
            var createUser = _a.createUser;
            responseProcessCallback(createUser);
        },
    }), createUserResult = _g[0], _h = _g[1], data = _h.data, loading = _h.loading, error = _h.error;
    var _j = useRequestRecoveryPasswordMutation({
        onCompleted: function (_a) {
            var _b;
            var requestRecoveryPassword = _a.requestRecoveryPassword;
            if ((requestRecoveryPassword === null || requestRecoveryPassword === void 0 ? void 0 : requestRecoveryPassword.__typename) === 'ValidationErrors') {
                (_b = requestRecoveryPassword.fields) === null || _b === void 0 ? void 0 : _b.forEach(function (f) {
                    //@ts-ignore
                    methods.setError('login', {
                        type: 'manual',
                        message: f === null || f === void 0 ? void 0 : f.messages[0],
                    });
                });
            }
        },
    }), requestPasswordRecoveryResult = _j[0], requestPasswordRecoveryData = _j[1];
    var requestPasswordData = get(requestPasswordRecoveryData.data, 'requestRecoveryPassword');
    var handleClickShowPassword = function () {
        setValues(__assign(__assign({}, values), { showPassword: !values.showPassword }));
    };
    var handleMouseDownPassword = function (event) {
        event.preventDefault();
    };
    var login = (React.createElement(Controller, { name: "login", control: methods.control, rules: {
            required: '',
        }, render: function (_a) {
            var _b, _c;
            var field = _a.field, fieldState = _a.fieldState, formState = _a.formState;
            return (React.createElement(TextField, __assign({}, field, { disabled: formState.isSubmitting, error: !!((_b = fieldState.error) === null || _b === void 0 ? void 0 : _b.message), helperText: (_c = fieldState.error) === null || _c === void 0 ? void 0 : _c.message, sx: { width: '100%' }, id: "login", variant: "outlined", label: "email" })));
        } }));
    var password = (React.createElement(Controller, { name: "password", control: methods.control, rules: {
            required: '',
        }, shouldUnregister: true, render: function (_a) {
            var _b, _c;
            var field = _a.field, fieldState = _a.fieldState, formState = _a.formState;
            return (React.createElement(TextField, __assign({}, field, { type: values.showPassword ? 'text' : 'password', disabled: formState.isSubmitting, variant: "outlined", label: t('common.password'), error: !!((_b = fieldState.error) === null || _b === void 0 ? void 0 : _b.message), helperText: (_c = fieldState.error) === null || _c === void 0 ? void 0 : _c.message, InputProps: {
                    notched: false,
                    endAdornment: (React.createElement(InputAdornment, { position: "end" },
                        React.createElement(IconButton, { "aria-label": "toggle password visibility", onClick: handleClickShowPassword, onMouseDown: handleMouseDownPassword, edge: "end" }, values.showPassword ? (React.createElement(VisibilityOff, { viewBox: "-6 -6 36 36" })) : (React.createElement(Visibility, { viewBox: "-6 -9 36 36" }))))),
                } })));
        } }));
    var termsLabel = (React.createElement(React.Fragment, null,
        t('checkboxes.accept'),
        ' ',
        React.createElement("a", { href: "/" + locale + "/iata-terms-and-conditions", target: "_blank", rel: "noreferrer" }, t('checkboxes.IATAURL')),
        ",",
        ' ',
        React.createElement("a", { href: "/" + locale + "/terms-and-conditions", target: "_blank", rel: "noreferrer" }, t('checkboxes.TermsURL')),
        ",",
        ' ',
        React.createElement("a", { href: "/" + locale + "/special-needs", target: "_blank", rel: "noreferrer" }, t('checkboxes.SpecialNeedsURL'))));
    var personalDataLabel = (React.createElement(React.Fragment, null,
        t('checkboxes.personalDataString'),
        ' ',
        React.createElement("a", { href: "/" + locale + "/privacy", target: "_blank", rel: "noreferrer" }, t('checkboxes.personalDataURL')),
        "."));
    var customSignUpSubmit = function (e) {
        e.preventDefault();
        return methods.handleSubmit(signUpSubmit)(e);
    };
    var theme = useTheme();
    var signInSubmit = function () {
        var values = methods.getValues();
        loginResult({
            variables: {
                input: { login: values.login, password: values.password },
            },
        });
    };
    var signUpSubmit = function () {
        var values = methods.getValues();
        createUserResult({
            variables: { input: { login: values.login, password: values.password } },
        });
    };
    var customRequestPasswordSubmit = function (e) {
        e.preventDefault();
        var values = methods.getValues();
        requestPasswordRecoveryResult({
            variables: { input: { email: values.login } },
        });
    };
    var tabsMap = {
        signIn: (React.createElement(DialogRoot, null,
            React.createElement("form", { style: { display: 'flex', flexDirection: 'column' } },
                React.createElement(DialogTitle, null, t('login.connect')),
                React.createElement(Typography, null,
                    t('login.newUser'),
                    ' ',
                    React.createElement(Button, { variant: "text", sx: { px: 0 }, onClick: function () {
                            methods.setValue('password', '');
                            changeTabHandler('signUp');
                        } }, t('login.createAccount'))),
                React.createElement(Stack, { sx: { my: 3, width: '100%' } },
                    login,
                    React.createElement(Button, { sx: { padding: '8px 0', alignSelf: 'flex-end' }, variant: "text", onClick: function () {
                            changeTabHandler('resetPassword');
                        } }, t('login.forgotPassword')),
                    password),
                React.createElement(Button, { variant: "contained", size: "large", startIcon: React.createElement(ArrowForward, { viewBox: "-14 -5 22 22" }), sx: { width: '100%', height: 48 }, onClick: function (e) {
                        e.preventDefault();
                        signInSubmit();
                    } }, loginData.loading ? React.createElement(CircularProgress, null) : t('login.proceed'))))),
        signUp: (React.createElement(DialogRoot, null,
            React.createElement("form", { onSubmit: customSignUpSubmit, style: { display: 'flex', flexDirection: 'column' } },
                React.createElement(DialogTitle, null, t('login.connect')),
                React.createElement(Typography, null,
                    t('login.alreadyRegistered'),
                    ' ',
                    React.createElement(Button, { variant: "text", sx: { px: 0 }, onClick: function () {
                            methods.setValue('password', '');
                            changeTabHandler('signIn');
                        } }, t('login.connect'))),
                React.createElement(Stack, { sx: { my: 3, width: '100%' } },
                    login,
                    React.createElement(Spacer, { size: 40 }),
                    password),
                React.createElement(Controller, { name: "terms", rules: {
                        required: 'Please accept',
                    }, control: methods.control, render: function (_a) {
                        var field = _a.field, fieldState = _a.fieldState, formState = _a.formState;
                        return (React.createElement(FormControl, __assign({ fullWidth: true }, field, { error: !!fieldState.error, sx: __assign({ fontSize: '14px' }, (!!fieldState.error && {
                                border: '1px solid #ffd5d5',
                                borderRadius: '8px',
                                padding: '8px 12px',
                                background: '#fff4f4',
                            })) }),
                            React.createElement(FormControlLabel, __assign({}, field, { sx: __assign({}, (!!fieldState.error && {
                                    '& .MuiSvgIcon-root': {
                                        color: theme.palette.error.main,
                                    },
                                })), control: React.createElement(Checkbox, __assign({}, field, { checked: field.value, onChange: function (e) {
                                        field.onChange(e.target.checked);
                                    } })), label: React.createElement(Typography, { variant: "subtitle1" }, termsLabel) }))));
                    } }),
                React.createElement(Controller, { name: "dataProcessing", rules: {
                        required: 'Please accept',
                    }, control: methods.control, render: function (_a) {
                        var field = _a.field, fieldState = _a.fieldState, formState = _a.formState;
                        return (React.createElement(FormControl, __assign({ fullWidth: true }, field, { error: !!fieldState.error, sx: __assign({ fontSize: '14px' }, (!!fieldState.error && {
                                border: '1px solid #ffd5d5',
                                borderRadius: '8px',
                                padding: '8px 12px',
                                background: '#fff4f4',
                            })) }),
                            React.createElement(FormControlLabel, __assign({}, field, { control: React.createElement(Checkbox, __assign({}, field, { checked: field.value, sx: __assign({}, (!!fieldState.error && {
                                        '& .MuiSvgIcon-root': {
                                            color: theme.palette.error.main,
                                        },
                                    })), onChange: function (e) {
                                        field.onChange(e.target.checked);
                                    } })), label: React.createElement(Typography, { variant: "subtitle1" }, personalDataLabel) }))));
                    } }),
                React.createElement(Controller, { name: "newsletter", control: methods.control, render: function (_a) {
                        var field = _a.field, fieldState = _a.fieldState, formState = _a.formState;
                        return (React.createElement(FormControl, __assign({ fullWidth: true }, field, { error: !!fieldState.error }),
                            React.createElement(FormControlLabel, __assign({}, field, { control: React.createElement(Checkbox, __assign({}, field, { checked: field.value, onChange: function (e) {
                                        field.onChange(e.target.checked);
                                    } })), label: React.createElement(Typography, { variant: "subtitle1" }, t('checkboxes.newsletter', {
                                    configSiteName: configSiteName,
                                })) }))));
                    } }),
                React.createElement(Button, { type: "submit", variant: "contained", size: "large", startIcon: React.createElement(ArrowForward, { viewBox: "-14 -5 22 22" }), sx: { width: '100%', height: 48 } }, loading ? React.createElement(CircularProgress, null) : t('common.create_account'))))),
        resetPassword: (React.createElement(DialogRoot, null, requestPasswordData &&
            requestPasswordData.__typename === 'RequestRecoveryPassword' ? (React.createElement(React.Fragment, null,
            React.createElement(Stack, { justifyContent: "center", alignItems: "center", sx: {
                    width: '100%',
                    fontSize: 14,
                    mt: '30%',
                    textAlign: 'center',
                }, dangerouslySetInnerHTML: { __html: requestPasswordData.message } }),
            React.createElement(Button, { variant: "contained", size: "large", startIcon: React.createElement(ArrowForward, { viewBox: "-14 -5 22 22" }), sx: { width: '100%', height: 48, mt: 3 }, onClick: onClose }, "\u00CEnchide"))) : (React.createElement("form", { onSubmit: customRequestPasswordSubmit, style: { display: 'flex', flexDirection: 'column' } },
            React.createElement(DialogTitle, null, t('login.forgotPassword')),
            React.createElement(Typography, null,
                t('login.alreadyRegistered'),
                React.createElement(Button, { variant: "text", sx: { px: 0 }, onClick: function () {
                        changeTabHandler('signIn');
                    } }, t('login.connect'))),
            React.createElement(Stack, { sx: { my: 3, width: '100%' } }, login),
            React.createElement(Button, { variant: "contained", type: "submit", size: "large", startIcon: React.createElement(ArrowForward, { viewBox: "-14 -5 22 22" }), sx: { width: '100%', height: 48 } }, t('login.resetPassword')))))),
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Dialog, { PaperProps: { sx: { borderRadius: 3, p: 2 } }, onClose: onClose, fullScreen: isMobile, open: open },
            React.createElement(Cancel, { onClick: onClose, viewBox: "-1 -2 20 20", sx: {
                    position: 'absolute',
                    top: 8,
                    right: 8,
                    stroke: '#1B1464',
                    cursor: 'pointer',
                } }),
            tabsMap[activeTab],
            React.createElement(SocialLogin, null))));
};
export default Login;
