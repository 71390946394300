import { Button, capitalize, Card, Stack, Typography } from '@material-ui/core';
import React, { useRef, useState } from 'react';
import FlightMobile from './FlightMobile';
import FlightCardMobileDetails from './FlightCardMobileDetails';
import { Box } from '@material-ui/system';
import { FareRules } from './FareRules';
import ArrowDownward from '../../assets/icons/ArrowDownward';
import ArrowUpward from '../../assets/icons/ArrowUpward';
import ArrowForwardIos from '../../assets/icons/ArrowForwardIos';
import { Link } from 'react-router-dom';
import { BaggageDetails } from './BaggageDetails';
import useTranslation, { useLocaleId } from '../../hooks/useTranslation';
import getCurrencySymbol from '../../util/getCurrencySymbol';
import CheckinRequiredIcon from 'app/assets/icons/CheckinRequiredIcon';
import CheckInRequiredPayments from 'app/assets/icons/CheckinRequredPayments';
import errorOutline from 'app/assets/icons/ErrorOutline.svg';
var FlightCardMobile = function (_a) {
    var _b, _c, _d, _e, _f, _g, _h, _j, _k;
    var itinerary = _a.itinerary, sectors = _a.sectors, noPrice = _a.noPrice, departureDateType = _a.departureDateType, noBaggageDetails = _a.noBaggageDetails, multicity = _a.multicity, totalPassengerNumber = _a.totalPassengerNumber, isCheckinRequired = _a.isCheckinRequired;
    var _l = useState(false), isOpen = _l[0], setIsOpen = _l[1];
    var anchorEl = useRef(null);
    var t = useTranslation();
    var locale = useLocaleId();
    var handleOpen = function () {
        // anchorEl!.current!.scrollIntoView({ behavior: 'smooth' });
        setIsOpen(true);
    };
    var minSectorsSeatsAmount = sectors.filter(function (sec) { return sec.seats; }).filter(function (sec) { return sec.seats; })
        .length > 0 &&
        sectors
            .filter(function (sec) { return sec.seats; })
            .reduce(function (prev, curr) { return (prev.seats < curr.seats ? prev : curr); })
            .seats;
    return (React.createElement(Card, { ref: anchorEl, sx: {
            width: 'calc(100% - 32px)',
            mt: 2,
            alignSelf: 'center',
        } },
        React.createElement(Stack, { direction: "column", justifyContent: "space-between" },
            React.createElement(Stack, { direction: "row", spacing: 1, justifyContent: "space-between", sx: { borderBottom: '1px solid #E3F2FD' } },
                React.createElement(Stack, { sx: { flex: 1 }, onClick: function () { return setIsOpen(!isOpen); } }, sectors.map(function (sector, index) { return (React.createElement(FlightMobile, { key: index, sector: sector, expanded: isOpen, last: index === sectors.length - 1 })); }))),
            !isOpen ? (React.createElement(Stack, { sx: { height: '53px' } }, noPrice ? (React.createElement(Button, { variant: "contained", sx: {
                    m: 1,
                    justifyContent: noPrice ? 'center' : 'space-between',
                }, onClick: handleOpen },
                !noPrice && (React.createElement(Typography, { variant: "h4" },
                    getCurrencySymbol((_c = (_b = itinerary === null || itinerary === void 0 ? void 0 : itinerary.price) === null || _b === void 0 ? void 0 : _b.currency) === null || _c === void 0 ? void 0 : _c.code),
                    ' ',
                    (parseFloat(itinerary.price.amount) /
                        totalPassengerNumber).toFixed(2),
                    ' ')),
                React.createElement(Stack, { direction: "row", spacing: 1, alignItems: "center" },
                    React.createElement(Typography, { fontSize: 16 }, t('flightCard.showDetails')),
                    React.createElement(ArrowDownward, { viewBox: "-4 -6 24 24" })))) : (React.createElement(Button, { component: Link, to: "/" + locale + "/booking/" + itinerary.id, variant: "contained", sx: { m: 1, justifyContent: 'space-between' }, onClick: function () { return setIsOpen(!isOpen); } },
                React.createElement(Typography, { variant: "h4" },
                    getCurrencySymbol((_e = (_d = itinerary === null || itinerary === void 0 ? void 0 : itinerary.price) === null || _d === void 0 ? void 0 : _d.currency) === null || _e === void 0 ? void 0 : _e.code),
                    ' ',
                    (parseFloat(itinerary.price.amount) / totalPassengerNumber).toFixed(2)),
                React.createElement(Stack, { direction: "row", spacing: 1, alignItems: "center" },
                    React.createElement(Typography, { fontSize: 16 }, t('flightCard.pickFlight')),
                    React.createElement(ArrowDownward, { sx: { transform: 'rotate(-90deg)' }, viewBox: "-7 -6 24 24" })))))) : (React.createElement(Stack, null,
                React.createElement(Button, { variant: "contained", sx: {
                        m: 1,
                        justifyContent: noPrice ? 'center' : 'space-between',
                    }, onClick: function () { return setIsOpen(!isOpen); } },
                    !noPrice && (React.createElement(Typography, { variant: "h3" },
                        getCurrencySymbol((_g = (_f = itinerary === null || itinerary === void 0 ? void 0 : itinerary.price) === null || _f === void 0 ? void 0 : _f.currency) === null || _g === void 0 ? void 0 : _g.code),
                        ' ',
                        (parseFloat(itinerary.price.amount) / totalPassengerNumber).toFixed(2))),
                    React.createElement(Stack, { direction: "row", spacing: 1, alignItems: "center" },
                        React.createElement(Typography, { fontSize: 16 }, t('flightCard.hideDetails')),
                        React.createElement(ArrowUpward, { viewBox: "-4 -6 24 24" }))),
                sectors.map(function (sector, index) { return (React.createElement(FlightCardMobileDetails, { key: index, sector: sector, flightIndex: index, sectorsLength: sectors.length })); }),
                React.createElement(Stack, { sx: { borderTop: '1px solid #E3F2FD', mt: 2 }, direction: "column" },
                    minSectorsSeatsAmount && (React.createElement(Typography, { alignSelf: "center", color: minSectorsSeatsAmount <= 3 ? 'error.main' : 'text.secondary', sx: { mt: 1, display: 'flex', alignItems: 'center' }, variant: "subtitle1" },
                        React.createElement("img", { style: { marginRight: 5, paddingBottom: 2 }, src: errorOutline }),
                        minSectorsSeatsAmount > 3
                            ? minSectorsSeatsAmount + " " + t('flightCard.seatsRemaining')
                            : minSectorsSeatsAmount === 1
                                ? t('flightCard.lastSeat')
                                : t('flightCard.lastSeatsPre') + " " + minSectorsSeatsAmount + " " + t('flightCard.lastSeatsPost'))),
                    isCheckinRequired && (React.createElement(Stack, { sx: { px: 2, py: 1 } },
                        React.createElement(Stack, { direction: "row", alignItems: "center", justifyContent: "space-between", sx: { fontWeight: 600 } },
                            React.createElement(Stack, { alignItems: "center", direction: "row" },
                                React.createElement(CheckinRequiredIcon, null),
                                t('flightCard.checkInAutomat')),
                            React.createElement(Stack, { alignItems: "center", direction: "row", sx: {
                                    ml: 2,
                                    borderRadius: 4,
                                    px: 2,
                                    background: '#4BC25F',
                                    color: 'common.white',
                                } },
                                React.createElement(CheckInRequiredPayments, null),
                                React.createElement(Typography, { sx: { ml: 1 } }, t('flightCard.checkInAutomatFree')))),
                        React.createElement(Stack, { sx: { py: 2, mt: 1, display: 'inline' } },
                            React.createElement(Typography, { sx: { display: 'inline' } }, t('flightCard.checkInAutomatText')),
                            ' ',
                            React.createElement(Link, { to: "/" + locale + "/check-in", target: "_blank" }, t('flightCard.checkInAutomatButtonText'))))),
                    React.createElement(Box, { sx: { '& button': { m: 1 } } },
                        React.createElement(Stack, { direction: "row", alignSelf: "center", justifyContent: "center" },
                            !noBaggageDetails && (React.createElement(BaggageDetails, { offerId: itinerary.id })),
                            React.createElement(FareRules, { offerId: itinerary.id }))),
                    !noPrice && (React.createElement(Button, { color: "success", component: Link, to: "/" + locale + "/booking/" + itinerary.id, variant: "contained", sx: {
                            m: 1,
                            justifyContent: 'space-between',
                            color: 'primary.contrastText',
                        }, onClick: function () { return setIsOpen(!isOpen); } },
                        React.createElement(Typography, { variant: "h3" },
                            getCurrencySymbol((_j = (_h = itinerary === null || itinerary === void 0 ? void 0 : itinerary.price) === null || _h === void 0 ? void 0 : _h.currency) === null || _j === void 0 ? void 0 : _j.code),
                            ' ',
                            (parseFloat(itinerary.price.amount) /
                                totalPassengerNumber).toFixed(2)),
                        React.createElement(Stack, { direction: "row", spacing: 1, alignItems: "center" },
                            React.createElement(Typography, { fontSize: 16 }, capitalize(t('flightCard.book').toLowerCase())),
                            React.createElement(ArrowForwardIos, { viewBox: "-4 -6 24 24" }))))))),
            !noPrice && (React.createElement(Typography, { alignSelf: "center", color: "text.secondary", variant: "subtitle1", mb: 1 },
                t('flightCard.tarifPerPassenger'),
                itinerary.fee &&
                    " + " + getCurrencySymbol((_k = itinerary.fee.currency) === null || _k === void 0 ? void 0 : _k.code) + (parseFloat(itinerary.fee.amount) / totalPassengerNumber).toFixed() + " " + t('flightCard.tsf'))))));
};
export default FlightCardMobile;
