import React, { useState, useRef } from 'react';
import { Menu, MenuItem, Typography, Box, useMediaQuery, NativeSelect, } from '@material-ui/core';
import { LocaleContext } from '../../hooks/useTranslation';
import Language from 'app/assets/icons/Language';
var languages = {
    bg: 'BG',
    en: 'EN',
};
var LocaleMenu = function (_a) {
    var paletteMode = _a.paletteMode;
    var _b = useState(null), anchorEl = _b[0], setAnchorEl = _b[1];
    var nativeSelectRef = useRef(null);
    var isMobile = useMediaQuery(function (theme) { return theme.breakpoints.down('sm'); });
    var _c = React.useContext(LocaleContext), locale = _c.locale, updateLocale = _c.updateLocale;
    function handleClick(event) {
        if (anchorEl !== event.currentTarget) {
            setAnchorEl(event.currentTarget);
        }
    }
    function handleClose() {
        setAnchorEl(null);
    }
    return isMobile ? (React.createElement(Box, { sx: { position: 'relative' } },
        React.createElement(Box, { sx: { display: 'flex', position: 'absolute', top: '0', left: '0' } },
            React.createElement(Language, { stroke: "#FDAA28", viewBox: "-5 -5 24 24", sx: { fontSize: '2rem' } })),
        React.createElement(NativeSelect, { ref: nativeSelectRef, defaultValue: locale, onChange: function (e) { return updateLocale(e.target.value, locale); }, sx: {
                width: '32px',
                height: '32px',
                opacity: '0',
                overflow: 'hidden',
                '& .MuiNativeSelect-select': {
                    padding: '0 !important',
                },
            } }, Object.entries(languages).map(function (_a) {
            var code = _a[0], text = _a[1];
            return (React.createElement("option", { key: code, value: code }, text));
        })))) : (React.createElement(React.Fragment, null,
        React.createElement(Typography, { color: paletteMode === 'dark' ? 'common.white' : 'text.primary', sx: {
                display: 'flex',
                alignItems: 'center',
            }, onMouseOver: !isMobile ? handleClick : function () { }, onClick: handleClick },
            React.createElement(Box, { sx: {
                    width: { xs: '36px', md: '24px' },
                    height: { xs: '36px', md: '24px' },
                } },
                React.createElement(Language, { stroke: "#FDAA28", viewBox: "-5 -5 24 24", style: { width: '100%', height: '100%' } })),
            React.createElement(Box, { sx: { display: { xs: 'none', md: 'flex' } } }, languages[locale])),
        React.createElement(Menu, { PaperProps: {
                style: {
                    width: 95,
                },
            }, anchorEl: anchorEl, open: Boolean(anchorEl), onClose: handleClose, MenuListProps: { onMouseLeave: handleClose } }, Object.entries(languages).map(function (_a) {
            var code = _a[0], text = _a[1];
            return (React.createElement(MenuItem, { key: code, onClick: function () {
                    updateLocale(code, locale);
                    handleClose();
                } },
                React.createElement(Typography, { color: "text.primary" }, text)));
        }))));
};
export default LocaleMenu;
