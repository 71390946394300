var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useEffect, useState } from 'react';
import { ButtonSC, ContainerSC, ContentSC, LeftSC, RightSC, TitleSC, WrapperSC, MobileDialogWrapperSC, } from './styles';
import { CookieDialog } from './Dialog';
import { isMobile } from 'react-device-detect';
import { Dialog, Paper, DialogTitle, DialogContent, DialogActions, } from '@mui/material';
import { styled } from '@mui/material';
import classnames from 'classnames';
import useTranslation, { useLocaleId } from 'app/hooks/useTranslation';
import Cookies from 'js-cookie';
import { configGdpr } from 'app/util/siteConfig';
var StyledDialog = styled(Dialog)(function () { return ({
    "&.MuiPaper-root[role='dialog']": {
        margin: '5px !important',
        height: 'unset !important',
    },
}); });
export var CookieBanner = function () {
    var t = useTranslation();
    var locale = useLocaleId();
    var _a = useState(false), visible = _a[0], setVisible = _a[1];
    var _b = useState(false), dialogOpen = _b[0], setDialogOpen = _b[1];
    var _c = useState(false), mobileDialogOpen = _c[0], setMobileDialogOpen = _c[1];
    var _d = useState({
        infrastructure: {
            opened: false,
            checked: true,
        },
        base: {
            opened: false,
            checked: true,
        },
        security: {
            opened: false,
            checked: true,
        },
        market: {
            opened: false,
            checked: false,
        },
        stats: {
            opened: false,
            checked: false,
        },
    }), openState = _d[0], setOpenState = _d[1];
    useEffect(function () {
        var gdpr = Cookies.get(configGdpr);
        if (!gdpr) {
            if (isMobile) {
                setMobileDialogOpen(true);
            }
            else {
                setVisible(true);
            }
        }
    }, [isMobile]);
    var handleClose = function () {
        setDialogOpen(false);
    };
    var handleOpen = function () {
        setDialogOpen(true);
    };
    var handleMobileClose = function (event, reason) {
        if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
            setMobileDialogOpen(false);
        }
    };
    var toggle = function (key) {
        var _a;
        setOpenState(__assign(__assign({}, openState), (_a = {}, _a[key] = __assign(__assign({}, openState[key]), { opened: !openState[key].opened }), _a)));
    };
    var check = function (key) {
        var _a;
        setOpenState(__assign(__assign({}, openState), (_a = {}, _a[key] = __assign(__assign({}, openState[key]), { checked: !openState[key].checked }), _a)));
    };
    var save = function (all) {
        var categories;
        if (all) {
            categories = Object.keys(openState).reduce(function (acc, val) {
                acc[val] = true;
                return acc;
            }, {});
        }
        else {
            categories = Object.entries(openState).reduce(function (acc, _a) {
                var k = _a[0], v = _a[1];
                acc[k] = v.checked;
                return acc;
            }, {});
        }
        Cookies.set(configGdpr, JSON.stringify(categories), {
            expires: 30,
        });
        setVisible(false);
        setMobileDialogOpen(false);
        setDialogOpen(false);
    };
    if (!visible && !isMobile)
        return null;
    return (React.createElement(WrapperSC, null,
        !isMobile && (React.createElement(ContainerSC, null,
            React.createElement(LeftSC, null,
                React.createElement(TitleSC, null, t('cookie.title')),
                React.createElement(ContentSC, null,
                    t('cookie.text'),
                    React.createElement("a", { href: "/" + locale + "/cookie-policy", target: "_blank" }, t('cookie.link')))),
            React.createElement(RightSC, null,
                React.createElement(ButtonSC, { onClick: function () { return save(true); } }, t('cookie.accept')),
                React.createElement(ButtonSC, { onClick: handleOpen, className: "outline" }, t('cookie.personalize'))))),
        React.createElement(StyledDialog, { className: "mobile-cookie-banner-wrapper", open: mobileDialogOpen, onClose: handleMobileClose, sx: { zIndex: '9999 !important' } },
            React.createElement(MobileDialogWrapperSC, null,
                React.createElement(Paper, { classes: { rounded: 'true' } },
                    React.createElement(DialogTitle, null, t('cookie.title')),
                    React.createElement(DialogContent, null,
                        React.createElement(ContentSC, null,
                            t('cookie.text'),
                            ' ',
                            React.createElement("a", { href: "/" + locale + "/cookie-policy", target: "_blank" }, t('cookie.link')))),
                    React.createElement(DialogActions, null,
                        React.createElement(ButtonSC, { onClick: function () { return save(true); } }, t('cookie.accept')),
                        React.createElement(ButtonSC, { onClick: handleOpen, className: "outline" }, t('cookie.personalize')))))),
        React.createElement(CookieDialog, { check: check, toggle: toggle, onSave: save, openState: openState, isOpen: dialogOpen, onClose: handleClose, className: classnames({ 'mobile-cookie-dialog': isMobile }) })));
};
